// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

import { DEFAULT_MUI_SIZE } from '../BimAdmin';

// eslint-disable-next-line react/prop-types
export const TagsField = ({ tags, onTagsChange }) => {
    return (
        <Autocomplete
            multiple
            id="tags"
            options={[]}
            value={tags}
            freeSolo
            size={DEFAULT_MUI_SIZE}
            sx={{ marginTop: 2 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Optional tags"
                    placeholder="Type and press enter"
                />
            )}
            onChange={onTagsChange}
        />
    );
};

// eslint-disable-next-line react/prop-types
export const TagsEdit = ({ tags, setTags }) => {
    const tagsArr = Object.keys(tags);
    return (
        <Autocomplete
            multiple
            id="tags"
            options={[]}
            value={tagsArr}
            freeSolo
            sx={{ marginTop: 4 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Labels"
                    placeholder={setTags ? 'Type and press enter' : ''}
                />
            )}
            disabled={!setTags}
        />
    );
};

/**
 * Update tags at each edit
 */
// rename to InstantTagEdit or SingleTagEdit
// eslint-disable-next-line react/prop-types
export const TagsListEdit = ({ tags, onTagAdded, onTagRemoved }) => {
    const tagsArr = Object.keys(tags);

    // add/remove tag in server
    const onTagsChange = async (event: unknown, newTags: string[]) => {
        // compute diff to find added or removed tag
        const diffCount = newTags.length - tagsArr.length;
        const isAdded = diffCount > 0;
        const tagsCount = {};
        [...tagsArr, ...newTags].forEach(
            (tagName) => (tagsCount[tagName] = tagsCount[tagName] ? tagsCount[tagName] + 1 : 1),
        );
        const tag = Object.entries(tagsCount).find(([, count]) => count === 1)?.[0];
        console.log(`${isAdded ? 'added' : 'removes'} tag: ${tag}`);
        isAdded ? onTagAdded(tag) : onTagRemoved(tag);
    };

    return (
        <Autocomplete
            multiple
            id="tags"
            options={[]}
            value={tagsArr}
            freeSolo
            size={DEFAULT_MUI_SIZE}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label="Labels"
                    placeholder="Type and press enter"
                />
            )}
            onChange={onTagsChange}
        />
    );
};
