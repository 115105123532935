import {
    type AuthenticationResult,
    type Configuration,
    type EventMessage,
    EventType,
    type IPublicClientApplication,
    InteractionRequiredAuthError,
    type PopupRequest,
    PublicClientApplication,
    type SilentRequest,
} from '@azure/msal-browser';

import { BimTestApi } from '../api/bim';

export const tenant = process.env.TENANT_ID || '5d49a660-c20c-45c1-9d68-0a244376a6c3';
const bimApiAppName = process.env.BIM_API_APP_NAME || 'internal-bim-dev';

export enum MsalLoginType {
    POPUP = 'popup',
    REDIRECT = 'redirect',
    SILENT = 'silent',
}

export const MSAL_LOGIN_TYPE: MsalLoginType = MsalLoginType.POPUP;

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.CLIENT_ID || '2c0e31f3-6fa4-44c7-a9b0-c946abad1850', // gui
        authority: `https://login.microsoftonline.com/${tenant}/`,
        redirectUri: '/',
        postLogoutRedirectUri: '/',
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
const bimApiRequest: PopupRequest = {
    scopes: [`api://${bimApiAppName}/bim.readwrite.all`],
};

export const loginRequest: PopupRequest = bimApiRequest;

export const defaultMsalInstance = new PublicClientApplication(msalConfig);

export async function initialiseMsal(
    pca: PublicClientApplication = defaultMsalInstance,
): Promise<IPublicClientApplication> {
    await pca.initialize();

    const accounts = pca.getAllAccounts();

    if (accounts.length > 0) {
        const account = accounts.find((acc) => acc.tenantId === tenant) ?? accounts[0];

        if (accounts.length > 1) {
            console.debug('Multiple accounts found, guessed account for tenant, using:', {
                account: `${account.name} (${account.username})`,
                tenantId: account.tenantId,
                localAccountId: account.localAccountId,
                homeAccountId: account.homeAccountId,
            });
        }
        pca.setActiveAccount(account);
    }

    pca.addEventCallback(
        (event: EventMessage) => {
            if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
                const payload = event.payload as AuthenticationResult;
                const account = payload.account;
                pca.setActiveAccount(account);
            }
        },
        [EventType.LOGIN_SUCCESS],
    );

    BimTestApi.getSingleton().makeDefault();

    return pca;
}

export async function getAuthenticationWithScope(
    instance: PublicClientApplication = defaultMsalInstance,
    request: PopupRequest | SilentRequest = bimApiRequest,
    method: MsalLoginType = MsalLoginType.SILENT,
): Promise<AuthenticationResult> {
    const account = instance.getActiveAccount();
    if (!account) {
        throw Error(
            'No active account! Verify a user has been signed in and setActiveAccount has been called.',
        );
    }

    if (method === MsalLoginType.SILENT) {
        try {
            return await instance.acquireTokenSilent({
                account: account,
                ...request,
            });
        } catch (err) {
            if (err instanceof InteractionRequiredAuthError) {
                console.debug('Unable to retrieve token silently, falling back to popup');
                // fallback to interaction when silent call fails
                return getAuthenticationWithScope(instance, request, MsalLoginType.POPUP);
            }

            throw err;
        }
    } else if (method === MsalLoginType.POPUP) {
        return instance.acquireTokenPopup(request);
    } else if (method === MsalLoginType.REDIRECT) {
        throw new Error('MSAL login redirect not yet supported');
    } else {
        throw new Error(`Unknown MSAL login type when getting auth: ${method}`);
    }
}
