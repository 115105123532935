import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

import { NotificationService, NOTIF_TYPE } from '../../common/NotificationService';

export const Notifications = () => {
    const [notification, setNotification] = useState({ msg: '', type: null });

    useEffect(() => {
        console.log('Bind notifications component to NotificationService');
        NotificationService.setNotification = setNotification;
    }, []);

    const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        NotificationService.clearNotification();
    };

    return (
        <>
            <Snackbar
                open={notification.msg?.length > 0}
                autoHideDuration={6000}
                onClose={handleCloseNotification}
            >
                <Alert
                    onClose={handleCloseNotification}
                    severity={notification.type === NOTIF_TYPE.SUCCESS ? 'success' : 'error'}
                    sx={{ width: '100%' }}
                >
                    {notification.msg}
                </Alert>
            </Snackbar>
        </>
    );
};
