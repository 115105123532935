import { BimApi } from '../api/bim';
import { AntennaPatternsStub, BimType, PassiveTowerSubtypes } from '../models/BimDataTypes';
import { NotificationService } from './NotificationService';

export const exportRequestToCurl = (req: Request) => {
    let curlReq = `curl -X '${req.method}' `;
    curlReq += "'" + req.url + "' ";
    for (const element of req.headers.entries()) curlReq += `-H '${element[0]}:${element[1]}' `;
    return curlReq;
};

const UPLOAD_STEPS_MAPPING = ['CREATE_ATTACHMENT', 'BLOB_UPLOAD', 'FINALISE'];

export const uploadFile = async (file, modelId) => {
    let uploadStep = 0;
    console.log(`[FileUpload] STEP #${uploadStep + 1}: createAttachment`);
    let uploadData;
    const reply = await BimApi.used.createAttachment(modelId, 'file', file.name);

    if (reply.status === 201) {
        uploadData = await reply.json();
        const attachmentId = uploadData.id;
        uploadStep++;
        console.log(
            `[FileUpload] STEP #${
                uploadStep + 1
            }: uploadAttachment for attachmendId: ${attachmentId}`,
        );

        const blobUploadRequest = await BimApi.used.uploadAttachment(uploadData.upload, file, true);
        const finaliseRequest = await BimApi.used.finaliseAttachment(modelId, attachmentId, true);

        try {
            const reply2 = await fetch(blobUploadRequest);
            if (reply2?.status === 201) {
                uploadStep++;
                console.log(
                    `[FileUpload] STEP #${
                        uploadStep + 1
                    }: finaliseAttachment for bim: ${modelId} attachment: ${attachmentId} `,
                );
                const reply3 = await fetch(finaliseRequest);
                if (reply3?.status === 204) uploadStep++;
            }
            // return resp
        } catch (error) {
            console.error(error);
        }

        uploadStep < 3 && NotificationService.error('File uploading partially FAILED');

        uploadStep < 2 &&
            console.warn(
                `UPLOAD STEP ${uploadStep}: ${UPLOAD_STEPS_MAPPING[uploadStep]} FAILED => try manual upload using curl`,
                exportRequestToCurl(blobUploadRequest) + `--upload-file ${file.name}`,
            );

        uploadStep < 3 &&
            console.warn(
                `UPLOAD STEP ${uploadStep}: ${UPLOAD_STEPS_MAPPING[uploadStep]} FAILED => try manual upload using curl`,
                exportRequestToCurl(finaliseRequest) + `--upload-file ${file.name}`,
            );
    } else {
        // show error notification
        NotificationService.error('File uploading FAILED');
    }

    if (uploadStep === 3) {
        NotificationService.success('File uploaded');
    }

    return uploadData;
};

export async function makeApiRequest<T = unknown>(request: Request | string): Promise<T> {
    const response = await fetch(request);

    const responseData = await response.json();

    if (!response.ok) {
        // throw new HttpException(responseData, response.status);
    }

    return responseData.data;
}

export const convertArrayToTags = (tagsArr) => {
    const tags = {};
    // conert tags array to dict
    tagsArr.forEach((tag) => (tags[tag] = ''));
    return tags;
};

export const DEFAULT_FLOAT_ACCURACY = 2;

export const round2 = (val: number, decimals = DEFAULT_FLOAT_ACCURACY): number => {
    const accuracy = Math.pow(10, decimals);
    return Math.round(val * accuracy) / accuracy;
};

export const defaultIfUndef = (val, defaultVal = '') => (val !== undefined ? val : defaultVal);

export const isNotEmpty = (val) =>
    isNaN(val) && typeof val === 'string' ? val.length > 0 : val !== undefined && val !== '';

export const parseETiltMinMax = (input: string) => {
    const [min, max] = input.split('-');
    return {
        min: Number.parseInt(min),
        max: Number.parseInt(max),
    };
};

export const combinePorts = (target: AntennaPatternsStub, source: AntennaPatternsStub) => {
    const [min1, max1] = target.electricalTilt.split('-');
    const [min2, max2] = source.electricalTilt.split('-');
    const min = Math.min(Number.parseFloat(min1), Number.parseFloat(min2));
    const max = Math.max(Number.parseFloat(max1 ?? min1), Number.parseFloat(max2 ?? min2));
    target.electricalTilt = `${min}-${max}`;
};

export const isPassiveEquipment = (typeClass, type) =>
    typeClass && type && (typeClass === BimType.GROUND || PassiveTowerSubtypes.includes(type));
