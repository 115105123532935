import { FieldsCollection, NumInputField } from './GenericFields';
import { defaultIfUndef } from './utils';

export enum ShapeType {
    RECTANGLE = 'Rectangle',
    CYLINDER = 'Cylinder',
}

export interface RectangleShapeStub {
    height: number;
    width: number;
    length: number;
}

export interface CylinderShapeStub {
    diameter: number;
    depth: number;
}

export const ShapeTypes = [ShapeType.RECTANGLE, ShapeType.CYLINDER];

export const DEFAULT_SHAPE_TYPE = ShapeType.RECTANGLE;

// quite boilerplate, there should be some optimizations
export class CylinderShapeFields extends FieldsCollection {
    constructor(stub?: CylinderShapeStub) {
        super();
        this.initFromStub(stub);
    }

    get diameter() {
        return this.getFieldValue('diameter');
    }

    set diameter(val) {
        this.setFieldValue('diameter', val);
    }

    get depth() {
        return this.getFieldValue('depth');
    }

    set depth(val) {
        this.setFieldValue('depth', val);
    }

    isValid() {
        return this.isValidField('depth') && this.isValidField('diameter');
    }

    initFromStub(stub?: CylinderShapeStub) {
        this.initField('depth', new NumInputField(defaultIfUndef(stub?.depth), 'Depth'));
        this.initField('diameter', new NumInputField(defaultIfUndef(stub?.diameter), 'Diameter'));
    }

    // force exporting all fields when at least one has been modified
    getPendingChanges() {
        return super.hasPendingChanges() ? super.toStub() : {};
    }
}

export class RectangleShapeFields extends FieldsCollection {
    constructor(stub?: RectangleShapeStub) {
        super();
        this.initFromStub(stub);
    }

    get width() {
        return this.getFieldValue('width');
    }

    set width(val) {
        this.setFieldValue('width', val);
    }

    get height() {
        return this.getFieldValue('height');
    }

    set height(val) {
        this.setFieldValue('height', val);
    }

    get length() {
        return this.getFieldValue('length');
    }

    set length(val) {
        this.setFieldValue('length', val);
    }

    isValid() {
        return (
            this.isValidField('width') && this.isValidField('height') && this.isValidField('length')
        );
    }

    initFromStub(stub?: RectangleShapeStub) {
        this.initField('height', new NumInputField(defaultIfUndef(stub?.height), 'Height'));

        this.initField('width', new NumInputField(defaultIfUndef(stub?.width), 'Width'));

        this.initField('length', new NumInputField(defaultIfUndef(stub?.length), 'Length'));
    }

    // force exporting all fields when at least one has been modified
    getPendingChanges() {
        return super.hasPendingChanges() ? super.toStub() : {};
    }
}

export type ShapeDimensionsFields = CylinderShapeFields | RectangleShapeFields;
