import { useMsal } from '@azure/msal-react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';

import { MSAL_LOGIN_TYPE, MsalLoginType, loginRequest } from '../../common/Auth';

export const SignInButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogin = async (loginType: MsalLoginType) => {
        setAnchorEl(null);

        if (loginType === MsalLoginType.POPUP) {
            return instance.loginPopup(loginRequest);
        }

        if (loginType === MsalLoginType.REDIRECT) {
            return instance.loginRedirect(loginRequest);
        }
    };

    return (
        <div>
            <Button onClick={(event) => setAnchorEl(event.currentTarget)} color="inherit">
                Login
            </Button>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleLogin(MSAL_LOGIN_TYPE)} key="signin">
                    Sign In
                </MenuItem>
            </Menu>
        </div>
    );
};
