import { BimApi } from '../api/bim';
import {
    BimApiStub,
    BimApiCreateStub,
    BimApiUpdateStub,
    BimAttachmentStub,
} from '../models/BimDataTypes';
import {
    BimInfoFields,
    BimRequiredFields,
    BimParameters,
    PortsConfiguration,
    BimMeshReferenceFields,
} from '../models/BimFields';
const BIM_ITEMS_FETCH_COUNT = 0;

export class BimModel {
    id: string;
    tags: Record<string, string> = {};
    infoFields: BimInfoFields;
    itemReference: BimMeshReferenceFields;
    model: { request?: Request } = {};
    thumbnail: { id: string; url: string } = { id: '', url: '' };
    requiredFields: BimRequiredFields;
    origin: string;
    isFullyLoaded = false; // hacky way to tell bim details have been loaded
    modelParameters: BimParameters;
    attachments: BimAttachment[] = [];
    portsConfiguration: PortsConfiguration;

    async retrieveModel(forceRefresh?) {
        // Fetch model OBJ and MTL content from BIM API
        if (!this.model.request || forceRefresh) {
            const request = await BimApi.used.buildGlbRequest(
                this.id,
                this.modelParameters.pendingChanges(),
            );

            this.model = { request };
        } else {
            console.info('[BimModel | retrieveModel] using cached model');
        }
    }

    /**
     * First trying to load with parameters included
     * If failing retrying without parameters
     */
    async failsafeLoad() {
        let reply = await BimApi.used.fetchDetails(this.id);
        if (reply.status !== 200) {
            console.warn(`fetch model with parameters failed, fallback to no parameters request`);
            reply = await BimApi.used.fetchDetails(this.id, false);
        }
        const bimStub: BimApiStub = await reply.json();
        this.initFromStub(bimStub);
        this.isFullyLoaded = true;
    }

    /**
     * Populates list of attachments, skip existing item if any
     */
    async preloadAttachments() {
        const reply = await BimApi.used.listAttachments(this.id);
        if (reply.status === 200) {
            const attachmentsStub = await reply.json();
            this.attachments = attachmentsStub.map((stub) => {
                const existingAttachment = this.attachments.find((a) => a.id === stub.id);
                return existingAttachment || new BimAttachment(stub, this.id);
            });
        }
    }

    getThumbnailAttachment() {
        return this.attachments.find((a) => a.id === this.thumbnail.id);
    }

    isValid() {
        return this.infoFields.isValid() && this.requiredFields.isValid();
    }

    hasPendingChanges() {
        return (
            this.infoFields.hasPendingChanges() ||
            this.requiredFields.hasPendingChanges() ||
            this.itemReference.hasPendingChanges()
        );
    }

    clearPendingChanges() {
        this.infoFields.pending = {};
        this.requiredFields.pending = {};
        this.itemReference.pending = {};
    }

    initFromStub(bimStub: BimApiStub) {
        this.infoFields = new BimInfoFields(bimStub);
        this.id = bimStub?.id || this.id;
        this.tags = bimStub?.tags || this.tags; // init/refresh tags
        this.itemReference = new BimMeshReferenceFields(bimStub?.itemReference);
        this.thumbnail.url = bimStub?.thumbnailDownload?.url || this.thumbnail.url;
        this.thumbnail.id = bimStub?.thumbnailAttachmentId || this.thumbnail.id;
        this.modelParameters = bimStub?.parameters
            ? new BimParameters(bimStub?.parameters)
            : this.modelParameters;
        this.requiredFields =
            this.requiredFields || new BimRequiredFields(bimStub?.unstructuredData);
        this.requiredFields.stub = bimStub?.unstructuredData;
    }

    // export object to bimapi create stub
    toUpdateStub(): BimApiUpdateStub | null {
        const itemReference = this.itemReference.toStub();
        const requiredFields = this.requiredFields.toStub();
        const pendingChanges = {
            displayName: this.infoFields.name,
            itemReference,
            unstructuredData: { ...requiredFields },
        };
        return this.hasPendingChanges() ? pendingChanges : null;
    }

    // export object to bimapi create stub
    toCreateStub(): BimApiCreateStub {
        return {
            displayName: this.infoFields.name,
            tags: this.tags,
            unstructuredData: this.requiredFields.toStub(),
        };
    }
}
export class BimAttachment {
    id;
    title;
    type;
    size;
    date;
    url;
    bimRefId; // reference to BIM parent
    isLoaded = false;

    constructor(stub: BimAttachmentStub, bimId) {
        this.id = stub.id;
        this.bimRefId = bimId;
        this.title = stub.title;
        this.type = stub.type;
    }

    /**
     * Load attachment details from api stub
     */
    async load(forceRefresh?) {
        if (!this.isLoaded || forceRefresh) {
            console.log(`[BimAttachment] fetch details for attachment ${this.id}`);
            this.isLoaded = true; // hack to prevent simultaneous fetch
            const reply = await BimApi.used.getAttachment(this.bimRefId, this.id);
            const attachmentDetails: BimAttachmentStub = await reply.json();
            this.size = attachmentDetails.size;
            this.date = attachmentDetails.finaliseDate;
            this.url = attachmentDetails.download?.url;
            console.debug(attachmentDetails);
        } else {
            console.warn(`Skip details fetching and use cache infos for attachment ${this.id}
             => use force refresh flag otherwise `);
        }
    }
    // TODO
    retrieveAttachment() {
        if (!this.url) {
            console.warn(
                `Skipping image attachment ${this.id} as no download information was found `,
            );
        } else {
            // TODO
        }
    }
}

/**
 * BimModel instances retrieved from BimApi
 */
export class BimFactory {
    static instances = {}; // index of local bim instances
    static isPreloaded = false;

    /**
     * Search/fetch items from BIM API service
     */
    static async searchFetch(
        textFilter: string,
        pageId: string,
        forceRefresh = false,
    ): Promise<string | void> {
        if (!BimFactory.isPreloaded || forceRefresh) {
            console.log('[BimFactory] list text-filter:', textFilter);
            BimFactory.isPreloaded = true; // hack to avoid simultaneous requests
            // repopulate local store
            BimFactory.instances = {};

            const resp = await BimApi.used.fetchItems(textFilter, BIM_ITEMS_FETCH_COUNT, pageId);

            if (resp.status === 200) {
                const data = await resp.json();
                let nextPage;
                for (const [h, v] of resp.headers.entries())
                    nextPage = h === 'x-sitesee-next-page-token' ? v : nextPage;
                const bimItems = data.map((stub: BimApiStub) => BimFactory.createFromStub(stub));
                bimItems.forEach((item) => (BimFactory.instances[item.id] = item));
                console.log(`[BimFactory] fetched ${bimItems.length} items from server`);
                return nextPage;
            } else {
                const errorMsg = `[BimFactory] error while fetching BIM items error code: ${resp.status}`;
                console.warn(errorMsg);
            }
        } else {
            console.warn(
                '[BimFactory] already populated, use forceRefresh to fetch data again from server',
            );
        }
    }

    static createFromStub(bimStub: BimApiStub) {
        const bimInstance = new BimModel();
        bimInstance.initFromStub(bimStub);
        return bimInstance;
    }

    static toArray(): Array<BimModel> {
        return Object.values(BimFactory.instances);
    }
}
