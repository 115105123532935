// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Typography, Button, Box } from '@mui/material';
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    DialogActions,
} from '@mui/material';

import { TagsField } from './shared/Tags';
import { BimApiCreateStub } from '../models/BimDataTypes';
import { NotificationService } from '../common/NotificationService';
import { BIM_DIALOG_TYPE, BimDialogService } from '../common/BimDialogService';
import { BimApi } from '../api/bim';
import { convertArrayToTags } from '../common/utils';
import { BimModel } from '../factory/bim';
import { BimCharacteristics, BimInfos, BimShapeDim, BimTypeSelect } from './BimComponents';
import { BimRequiredFields } from '../models/BimFields';

// eslint-disable-next-line react/prop-types
export const BimDialogs = ({ currentBimId, onDone }) => {
    const [openDialog, setOpenDialog] = useState(BIM_DIALOG_TYPE.None);
    const onCloseCallbackRef = useRef();

    useEffect(() => {
        console.log('Bind BimDialogs react component to BimDialogService static class');
        BimDialogService.setOpenReactState = setOpenDialog;
        BimDialogService.onCloseReactRef = onCloseCallbackRef;
    }, []);

    const createBimItem = async (bimCreateStub: BimApiCreateStub) => {
        console.log('createBimItem: ', bimCreateStub);
        const res = await BimApi.used.createItem(bimCreateStub);
        const resp = res.status === 201 ? await res.json() : null;
        setOpenDialog(BIM_DIALOG_TYPE.None);
        resp
            ? NotificationService.success(`successfully created item ${resp.id}`)
            : NotificationService.error(`error while creating item`);
        onDone();
    };

    const deleteBimItem = () => {
        console.log('deleteBimItem');
        BimApi.used.deleteItem(currentBimId).then((resp) => {
            console.log(resp);
            setOpenDialog(BIM_DIALOG_TYPE.None);
            resp.status === 204
                ? NotificationService.success(`successfully deleted item ${resp.id}`)
                : NotificationService.error(`error could not delete item`);
            onDone(true);
        });
    };

    const customDialogAction = () => {
        setOpenDialog(BIM_DIALOG_TYPE.None);
        onCloseCallbackRef.current();
    };

    return (
        <>
            {openDialog === BIM_DIALOG_TYPE.Create && (
                <BimCreateDialog
                    onClose={() => setOpenDialog(BIM_DIALOG_TYPE.None)}
                    onConfirm={createBimItem}
                />
            )}
            {openDialog === BIM_DIALOG_TYPE.Delete && (
                <BimDeleteDialog
                    onClose={() => setOpenDialog(BIM_DIALOG_TYPE.None)}
                    onConfirm={deleteBimItem}
                />
            )}
            {openDialog === BIM_DIALOG_TYPE.DeleteAttachment && (
                <BimDeleteAttachmentDialog
                    onClose={() => setOpenDialog(BIM_DIALOG_TYPE.None)}
                    onConfirm={customDialogAction}
                />
            )}
        </>
    );
};

// eslint-disable-next-line react/prop-types
const BimCreateDialog = ({ onClose, onConfirm }) => {
    const [, setRefresh] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const newBimModel = useMemo(() => {
        const bimModel = new BimModel();
        bimModel.initFromStub();
        return bimModel;
    }, []);

    const setBimField = (fieldName, fieldValue) => {
        newBimModel[fieldName] = fieldValue;
        setRefresh((current) => !current);
    };

    const onSubmit = () => {
        newBimModel.isValid() ? onConfirm(newBimModel.toCreateStub()) : setSubmitted(true);
    };

    newBimModel.requiredFields = newBimModel.requiredFields || new BimRequiredFields();

    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Create BIM item</DialogTitle>
            <DialogContent sx={{ flexGrow: 1, width: '90%', margin: 'auto' }}>
                <Typography
                    sx={{ m: 1 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Name
                </Typography>
                <BimInfos
                    bimModel={newBimModel}
                    validate={submitted}
                    editable={true}
                    displayIdAndName={false}
                />
                <Typography
                    sx={{ m: 1 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Characteristics
                </Typography>
                <BimCharacteristics
                    bimRequiredFields={newBimModel.requiredFields}
                    validate={submitted}
                    editable={true}
                />
                <Typography
                    sx={{ m: 1 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Type/Subtype
                </Typography>
                <BimTypeSelect
                    bimRequiredFields={newBimModel.requiredFields}
                    validate={submitted}
                    editable={true}
                />
                <Typography
                    sx={{ m: 1 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Shape & dimensions
                </Typography>
                <BimShapeDim
                    bimRequiredFields={newBimModel.requiredFields}
                    validate={submitted}
                    editable={true}
                    onChange={() => setRefresh((current) => !current)}
                />
                <Typography
                    sx={{ m: 1 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Labels
                </Typography>
                <Box sx={{ width: '90%', m: 'auto', mt: 1 }}>
                    <TagsField
                        tags={Object.keys(newBimModel.tags)}
                        onTagsChange={(event: unknown, newTags: string[]) =>
                            setBimField('tags', convertArrayToTags(newTags))
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit}>Create</Button>
            </DialogActions>
        </Dialog>
    );
};

// eslint-disable-next-line react/prop-types
const BimDeleteDialog = ({ onClose, onConfirm }) => {
    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Delete BIM item</DialogTitle>
            <DialogContent>
                <DialogContentText>Are you sure you want to delete BIM item?</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => onConfirm()}>Delete</Button>
            </DialogActions>
        </Dialog>
    );
};

// eslint-disable-next-line react/prop-types
const BimDeleteAttachmentDialog = ({ onClose, onConfirm }) => {
    return (
        <Dialog open={true} onClose={onClose} fullWidth maxWidth="xs">
            <DialogTitle>Delete BIM Attachment</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete BIM attachment?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={() => onConfirm()}>Delete</Button>
            </DialogActions>
        </Dialog>
    );
};
