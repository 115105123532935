export enum BIM_DIALOG_TYPE {
    None,
    Create,
    Delete,
    DeleteAttachment,
}
/**
 * expose global methods from react component
 */
export class BimDialogService {
    static setOpenReactState; // setDialog react callback
    static onCloseReactRef; // react ref

    static onDialogOpen(type) {
        BimDialogService.setOpenReactState(type);
    }

    static onCreateDialogOpen() {
        BimDialogService.onDialogOpen(BIM_DIALOG_TYPE.Create);
    }

    static onDeleteDialogOpen() {
        BimDialogService.onDialogOpen(BIM_DIALOG_TYPE.Delete);
    }

    static deleteAttachmentDialog(onDialogClose) {
        BimDialogService.onDialogOpen(BIM_DIALOG_TYPE.DeleteAttachment);
        BimDialogService.onCloseReactRef.current = onDialogClose;
    }
}
