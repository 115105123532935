import { NumInputRange } from '../models/DataTypes';
import { FieldsCollection, NumInputField } from './GenericFields';
import { defaultIfUndef } from './utils';

/**
 * Wrapping NumInputRange to control values
 */
export class RangeInputFields extends FieldsCollection {
    constructor(inputRange?: NumInputRange) {
        super();

        this.initField('min', new NumInputField(defaultIfUndef(inputRange?.min), 'Min'));

        this.initField('max', new NumInputField(defaultIfUndef(inputRange?.max), 'Max'));
    }

    get min() {
        return this.getFieldValue('min');
    }

    set min(val) {
        this.setFieldValue('min', val);
    }

    get max() {
        return this.getFieldValue('max');
    }

    set max(val) {
        this.setFieldValue('max', val);
    }

    isValid() {
        return this.isValidField('min') && this.isValidField('max');
    }

    toStub(): NumInputRange {
        return { min: this.min, max: this.max };
    }
}
