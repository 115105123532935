import React from 'react';

import {
    AppBar,
    FormControl,
    FormGroup,
    FormControlLabel,
    IconButton,
    InputBase,
    Search,
    Toolbar,
    Typography,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';

// eslint-disable-next-line react/prop-types
export default function BimSearchForm({ onSearchChange }) {
    const [state, setState] = React.useState({
        name: true,
        desc: false,
        tags: false,
    });

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <FormControl sx={{ p: 1 }} component="fieldset" variant="standard">
            {/* <FormLabel component="legend">Search in</FormLabel> */}
            <FormGroup row>
                {/* <FormLabel  sx={{ 'flexGrow': 1, margin: 'auto' }}> BIM Admin </FormLabel> */}
                <FormControlLabel
                    control={
                        <Search>
                            <SearchIconWrapper>
                                <SearchIcon />
                            </SearchIconWrapper>
                            <StyledInputBase
                                placeholder="Filter BIM Catalog..."
                                onChange={onSearchChange}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </Search>
                    }
                    label=""
                />
                {/* <Typography sx={{ 'flexGrow': 1 }} />
                 <FormControlLabel
                        control={
                            <Checkbox checked={state.name} onChange={handleChange} name="name" size="small" />
                        }
                        label="Name"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={state.desc} onChange={handleChange} name="desc" size="small" />
                        }
                        label="Desc"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox checked={state.tags} onChange={handleChange} name="tags" size="small" />
                        }
                        label="Tags"
                    /> */}
            </FormGroup>
            {/* <FormHelperText>Be careful</FormHelperText> */}
        </FormControl>
    );
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.5),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.95),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('lg')]: {
            width: '20ch',
            '&:focus': {
                width: '30ch',
            },
        },
    },
}));

export const BimSearchBar = () => {
    return (
        <AppBar sx={{ position: 'relative' }}>
            <Toolbar variant="dense">
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    //   onClick={() => setOpenDrawer(true)}
                    edge="start"
                >
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                    BIM Catalog
                </Typography>
                <Search>
                    <SearchIconWrapper>
                        <SearchIcon />
                    </SearchIconWrapper>
                    <StyledInputBase
                        placeholder="Search…"
                        inputProps={{ 'aria-label': 'search' }}
                    />
                </Search>
            </Toolbar>
        </AppBar>
    );
};
