/**
 * ENUM TYPE DEF
 */

import { CylinderShapeStub, RectangleShapeStub } from '../common/ShapeInputFields';

export enum BimType {
    TOWER = 'Tower',
    GROUND = 'Ground',
}

export enum TowerType {
    STRUCTURE = 'Structure',
    PANEL = 'Panel',
    DISH = 'Dish',
    RRU = 'Rru',
    OMNI = 'Omni',
    DIPOLE = 'Dipole',
    YAGI = 'Yagi',
    BOX = 'Box',
    RAU = 'Rau',
    OTHER = 'Other',
    GPS = 'Gps',
    AWL = 'Awl',
    HEADFRAME = 'Headframe',
    MOUNTS = 'Mounts',
}

export enum GroundType {
    FENCE = 'Fence',
    SHELTER = 'Shelter',
    LEASE = 'Lease',
    ELECTRICAL_BOX = 'ElectricalBox',
    CABLE_TRAY = 'CableTray',
}

export type BimSubtype = TowerType | GroundType;

/**
 * DATA TYPES
 */
export const BimTypes = [BimType.GROUND, BimType.TOWER];
// passive tower equipments (e.g. not emitting radiation)
export const PassiveTowerSubtypes = [
    TowerType.STRUCTURE,
    TowerType.BOX,
    TowerType.RAU,
    TowerType.RRU,
    TowerType.OTHER,
    TowerType.HEADFRAME,
    TowerType.MOUNTS,
];

export const BimTowerSubtypes = [
    TowerType.AWL,
    TowerType.BOX,
    TowerType.DIPOLE,
    TowerType.DISH,
    TowerType.GPS,
    TowerType.HEADFRAME,
    TowerType.MOUNTS,
    TowerType.OMNI,
    TowerType.OTHER,
    TowerType.PANEL,
    TowerType.RAU,
    TowerType.RRU,
    TowerType.STRUCTURE,
    TowerType.YAGI,
];

export const BimGroundSubtypes = [
    GroundType.CABLE_TRAY,
    GroundType.ELECTRICAL_BOX,
    GroundType.FENCE,
    GroundType.LEASE,
    GroundType.SHELTER,
];

/**
 * BIM API STUBS
 * Data expected to be received from server
 */

export interface BimParameterStub {
    default: number;
    displayName: string;
    name: string;
    units: string;
}

/**
 * Data expected to be received from backend
 */
export interface AntennaPatternsStub {
    port: number;
    minimumFrequency: number;
    maximumFrequency: number;
    measurementFrequency: number;
    polarisation: string;
    electricalTilt: string;
}

/**
 * User data sent through unstructuredData
 */
export interface PortConfigurationStub {
    port: number; // used as unique port identifier
    frequency: number;
    freqMin?: number;
    freqMax?: number;
    power: number;
    eTilt: string;
}

// key : "typeClass",
export interface BimRequiredFieldsStub {
    uid: string;
    makeModel: string;
    manufacturer: string;
    esa: string;
    nadModel: string;
    nadManufacturer: string;
    typeClass: string;
    type: string;
    shape: string;
    shapeDimensions: CylinderShapeStub | RectangleShapeStub | null;
    portsConfiguration: PortConfigurationStub | null;
}

export const generateBimRequiredFieldsDefaultStub: () => BimRequiredFieldsStub = () => ({
    uid: '',
    makeModel: '',
    manufacturer: '',
    esa: '',
    nadModel: '',
    nadManufacturer: '',
    typeClass: '',
    type: '',
    shape: '',
    shapeDimensions: null,
    portsConfiguration: null,
});

interface RequestInfo {
    url: string;
    method?: string;
    headers?: Record<string, string>;
}

export enum MeshReferenceType {
    HyperBim = 'HyperBIM',
    Attachment = 'attachment',
}

interface BaseMeshItemReference {
    type: MeshReferenceType;
}

interface AttachmentMeshItemReference extends BaseMeshItemReference {
    type: MeshReferenceType.Attachment;
    id: string;
}

interface HyperBimMeshItemReference extends BaseMeshItemReference {
    type: MeshReferenceType.HyperBim;
    id: number;
}

export type MeshItemReference = AttachmentMeshItemReference | HyperBimMeshItemReference;

export interface BimApiStub {
    id: string;
    displayName: string;
    itemReference: MeshItemReference;
    parameters?: BimParameterStub[];
    tags?: Record<string, string>;
    thumbnailAttachmentId?: string;
    thumbnailDownload?: RequestInfo;
    unstructuredData?: Record<string, unknown>;
    antennaPatterns?: AntennaPatternsStub[];
}

export interface BimAttachmentStub {
    type: string;
    id: string;
    title: string;
    finaliseDate?: string;
    download?: RequestInfo;
    size?: number;
}

export interface BimApiUpdateStub {
    displayName: string;
    unstructuredData: BimRequiredFieldsStub;
    itemReference?;
}

export interface BimApiCreateStub {
    displayName: string;
    itemReference: { type: string; id: number };
    tags?: Record<string, string>;
    unstructuredData: BimRequiredFieldsStub;
}
