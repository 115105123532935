// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Paper, Typography, Divider, Box, Button } from '@mui/material';

import { BimFactory, type BimModel } from '../factory/bim';
import { BimItemsList } from './BimList';
import { BimDetails } from './BimDetails';
import { BimAttachments } from './BimAttachments';
import BimSearchForm from './BimSearch';
import { NotificationService } from '../common/NotificationService';
import { uploadFile } from '../common/utils';
import { Notifications } from './shared/Notifications';
import { ImageUploader } from './shared/ImageUpload';
import { BimDialogs } from './BimDialogs';
import { BimDialogService } from '../common/BimDialogService';
import { BimApi } from '../api/bim';

export const DEFAULT_MUI_SIZE = 'small';

/**
 * Bim administration
 */
export const BimAdmin = () => {
    const [, setRefresh] = useState(false);
    const [searchPattern, setSearchPattern] = useState('');
    const [bimId, setBimId] = useState();

    const bimItems = BimFactory.toArray();
    const bimModel: BimModel = bimId ? BimFactory.instances[bimId] : null;

    // responsive dialog width
    const theme = useTheme();
    // By default normal size is lg
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isCompact = useMediaQuery(theme.breakpoints.down('md')); // below md => full size (xl)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isLarge = useMediaQuery(theme.breakpoints.up('lg')); // above lg => min size (md)
    const mainLayoutStyle = {
        display: 'grid',
        gridTemplateColumns: '22% auto',
        gridTemplateAreas: `'list details'`,
    };

    const getSearchedFields = (item) => {
        let searchStr = item.name ? item.name.toLowerCase() : '';
        searchStr += item.tags
            ? Object.keys(item.tags).reduce((res, val) => res + ' ' + val, ' ')
            : '';
        searchStr += item.requiredFields?.originalStub
            ? Object.values(item.requiredFields.originalStub)
                  .map((reqField) => (typeof reqField === 'string' ? reqField.toLowerCase() : ''))
                  .reduce((concat, val) => concat + ' ' + val, ' ')
            : '';
        return searchStr;
    };

    const filteredItems = searchPattern.length
        ? bimItems.filter((item) => getSearchedFields(item).includes(searchPattern))
        : bimItems;

    const preloadItems = async (doReset = false) => {
        doReset && setBimId(null);
        await BimFactory.searchFetch(' ', null, true);
        setRefresh((current) => !current);
    };

    const reload = async () => {
        await bimModel.failsafeLoad();
        setRefresh((current) => !current);
    };

    const reloadThumbnail = async () => {
        await bimModel.preloadAttachments();
        const thumbnailAttachment = bimModel.getThumbnailAttachment();
        await thumbnailAttachment?.load();
        bimModel.thumbnail.url = thumbnailAttachment.url;
        setRefresh((current) => !current);
    };

    const onThumbnailUpload = async (file) => {
        const { id: thumbnailAttachmentId } = await uploadFile(file, bimId);
        if (thumbnailAttachmentId) {
            console.log(
                `[onThumbnailUpload] FINAL STEP (#4): update model ${bimId} with thumbnailAttachmentId ${thumbnailAttachmentId} `,
            );
            const reply = await BimApi.used.updateItem(bimId, { thumbnailAttachmentId });
            if (reply?.status === 204) {
                NotificationService.success('Thumbnail successfully uploaded');
                await reload();
                reloadThumbnail();
            } else {
                NotificationService.error('an error occured during thumbnail uploading');
            }
        }
    };

    useEffect(() => {
        // populate bim data from server (this should only be done once)
        preloadItems();
    }, []);

    return (
        <>
            <div style={mainLayoutStyle}>
                <Paper
                    id="main"
                    style={{
                        gridArea: 'list',
                        width: '95%',
                        height: '90vh',
                        margin: 'auto',
                        marginTop: '10px',
                        position: 'relative',
                    }}
                >
                    <Box sx={{ display: 'flex', backgroundColor: '#f5f5f5', width: '100%' }}>
                        <BimSearchForm
                            onSearchChange={(e) =>
                                setSearchPattern(e.target.value?.toLowerCase() ?? '')
                            }
                        />
                    </Box>
                    <div style={{ overflowY: 'auto', height: '72vh' }}>
                        <BimItemsList
                            items={filteredItems}
                            selectItem={setBimId}
                            selectedId={bimId}
                        />
                    </div>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            marginBottom: 'auto',
                            position: 'absolute',
                            bottom: '0px',
                        }}
                    >
                        <Typography sx={{ flexGrow: 1 }} />
                        {bimId && (
                            <Button
                                onClick={BimDialogService.onDeleteDialogOpen}
                                variant="outlined"
                                sx={{ margin: 1 }}
                            >
                                Delete
                            </Button>
                        )}
                        <Button
                            onClick={BimDialogService.onCreateDialogOpen}
                            variant="outlined"
                            sx={{ margin: 1 }}
                        >
                            Create
                        </Button>
                    </Box>
                </Paper>
                <Paper
                    style={{
                        gridArea: 'details',
                        width: '95%',
                        height: '90vh',
                        margin: 'auto',
                        marginTop: '10px',
                        display: 'flex',
                    }}
                >
                    <div
                        style={{
                            width: '57%',
                            height: '85vh',
                            overflowY: 'auto',
                            paddingRight: '25px',
                            margin: 'auto',
                            marginRight: '0px',
                        }}
                    >
                        {bimId && <BimDetails itemId={bimId} />}
                    </div>
                    <Divider
                        orientation="vertical"
                        sx={{ height: '80%', mt: 'auto', mb: 'auto' }}
                    />
                    {bimModel && (
                        <div
                            style={{
                                width: '38%',
                                margin: 'auto',
                                marginTop: '20px',
                                height: '85vh',
                            }}
                        >
                            <Typography
                                sx={{ m: 1, mb: 2 }}
                                variant="subtitle2"
                                color="text.secondary"
                                component="div"
                            >
                                Thumbnail
                            </Typography>
                            <div style={{ width: '50%', margin: 'auto' }}>
                                <ImageUploader
                                    imgUrl={bimModel.thumbnail.url}
                                    upload={onThumbnailUpload}
                                />
                            </div>
                            <div style={{ height: '50vh', overflowY: 'auto' }}>
                                <BimAttachments bimId={bimId} />
                            </div>
                        </div>
                    )}
                </Paper>
            </div>
            {/* </Paper> */}
            <BimDialogs onDone={preloadItems} currentBimId={bimId} />
            <Notifications />
        </>
    );
};
