// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Typography, Button, Stack, Box, CircularProgress } from '@mui/material';

import { TagsListEdit } from './shared/Tags';
import { NotificationService } from '../common/NotificationService';
import { BimApi } from '../api/bim';
import { BimModel, BimFactory } from '../factory/bim';
import {
    BimCharacteristics,
    BimInfos,
    BimModelParameters,
    BimShapeDim,
    BimTypeSelect,
    MeshReference,
} from './BimComponents';
import { PortsPowerTable } from './PortsPowerTable';
import { DataFieldsAccessors } from '../common/GenericFields';
import { PortsConfiguration } from '../models/BimFields';
import { isPassiveEquipment } from '../common/utils';
import { FieldsGrid } from './shared/FieldsDisplay';

/**
 * Showing/editing BIM fields details:
 */
// eslint-disable-next-line react/prop-types
export const BimDetails = ({ itemId }) => {
    const [, setRefresh] = useState(false);
    const [updateInProgress, setUpdateInProgress] = useState(false);
    const [apdStub, setApdStub] = useState();
    const loadInProgressRef = useRef(false);

    const reload = async () => {
        loadInProgressRef.current = true;
        setRefresh((current) => !current);
        await bimModel.failsafeLoad();
        loadInProgressRef.current = false;
        setRefresh((current) => !current);
    };

    const onTagAdded = async (tag) => {
        const resp = await BimApi.used.pushTag(itemId, tag);
        console.log(resp);
        reload();
    };

    const onTagRemoved = async (tag) => {
        const resp = await BimApi.used.popTag(itemId, tag);
        console.log(resp);
        reload();
    };

    const onSaveFields = async () => {
        if (bimModel.hasPendingChanges()) {
            if (bimModel.isValid()) {
                setUpdateInProgress(true);
                const pendingChanges = bimModel.toUpdateStub();
                console.log(pendingChanges);
                const reply = await BimApi.used.updateItem(itemId, pendingChanges);
                if (reply.status === 200 || reply.status === 204) {
                    bimModel.clearPendingChanges();
                    NotificationService.success('Updated BIM fields');
                } else {
                    NotificationService.error('Failed to update BIM fields');
                    console.log(reply);
                }
                await reload();
                setUpdateInProgress(false);
            } else {
                console.log('invalid inputs');
            }
        } else {
            console.log('no pending changes to save');
        }
    };

    const fieldPropsAccessors: DataFieldsAccessors = {
        getFieldName: (key) => key,
        getFieldValue: (key) => requiredFields[key],
        setFieldValue: (key, val) => (requiredFields[key] = val),
        getCustomMsg: () => 'required field',
        getCustomWidth: () => 6,
        isEditable: () => true,
    };

    // query APD endpoint to retrieve ports configuration for specific nadModel and nadManufacturer
    const getPortsConfig = async () => {
        // TODO fetch infos from backend
        console.log('getPortsConfig');
        const { nadModel, nadManufacturer } = requiredFields;
        setApdStub(null);
        // no fields saved previously => don't show table
        if (nadModel && nadManufacturer) {
            console.log(
                `[BimDetails::getPortsConfig] current APD conf: nadModel ${nadModel} nadManufacturer ${nadManufacturer}`,
            );
            const res = (await BimApi.used.fetchApd(nadManufacturer, nadModel)) as Response;
            const { status } = res;
            if (status === 200) {
                // success this will allow table display
                const apdStubData = await res.json();
                console.log(apdStubData);
                setApdStub(apdStubData);
            } else if (status === 404) {
                NotificationService.error('Error: nadModel/nadManufacturer not found');
            } else {
                NotificationService.error('An error occured while requesting APD service');
            }
        } else {
            console.warn(`[BimDetails::getPortsConfig] missing nadModel and nadManufacturer`);
        }
    };
    // each time apd stub is renewed, refresh data showed in ports configuration table
    const portsConfiguration = useMemo(() => {
        return apdStub ? new PortsConfiguration(apdStub) : null;
    }, [apdStub]);

    useEffect(() => {
        reload();
        !hideEmeConfiguration && getPortsConfig();
        // if no thumbnail url exists, try to fetch thumbnail attachment to get it
    }, [itemId]);

    const bimModel: BimModel = BimFactory.instances[itemId];
    const { modelParameters, requiredFields } = bimModel || {};
    const invalidForm = !bimModel.isValid();
    const noPendingChanges = !bimModel.hasPendingChanges();
    const saveDisabled = false; //invalidForm || noPendingChanges    // not working for strange reasons!! #TODO check why save button stay disable

    const hideEmeConfiguration = isPassiveEquipment(requiredFields.typeClass, requiredFields.type);
    const loadInProgress = loadInProgressRef.current;

    console.log(
        `invalidForm: ${invalidForm}, no pending changes: ${noPendingChanges} => save disabled: ${saveDisabled}`,
    );
    return (
        <>
            <div style={{ overflowY: 'auto', height: '79vh' }}>
                {loadInProgress && (
                    <CircularProgress
                        size={30}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-15px',
                            marginLeft: '-15px',
                        }}
                    />
                )}
                <Typography
                    sx={{ m: 1, mb: 2, mt: 2 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Info
                </Typography>
                <BimInfos
                    bimModel={bimModel}
                    editable={true}
                    validate={true}
                    onValidityChange={() => setRefresh((current) => !current)}
                />
                <Typography
                    sx={{ m: 1, mb: 2, mt: 2 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Mesh Reference
                </Typography>
                <MeshReference bimModel={bimModel} editable={true} validate={true} />
                {bimModel?.requiredFields && (
                    <>
                        <Typography
                            sx={{ m: 1, mb: 1 }}
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                        >
                            Characteristics
                        </Typography>
                        <BimCharacteristics
                            bimRequiredFields={bimModel.requiredFields}
                            editable={true}
                            validate={true}
                            onChange={() => setRefresh((current) => !current)}
                        />
                        <Typography
                            sx={{ m: 1, mb: 1, mt: 2 }}
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                        >
                            Type/Subtype
                        </Typography>
                        <BimTypeSelect
                            bimRequiredFields={bimModel.requiredFields}
                            editable={true}
                            validate={true}
                            onChange={() => setRefresh((current) => !current)}
                        />
                        <Typography
                            sx={{ m: 1, mb: 0, mt: 2 }}
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                        >
                            Shape & dimensions
                        </Typography>
                        {/* <Box sx={{ width: "90%", m: "auto", mt: 1 }}> */}
                        <BimShapeDim
                            bimRequiredFields={bimModel.requiredFields}
                            editable={true}
                            validate={true}
                            onChange={() => setRefresh((current) => !current)}
                        />
                    </>
                )}

                {bimModel?.tags && (
                    <>
                        <Typography
                            sx={{ m: 1, mt: 2 }}
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                        >
                            Tags
                        </Typography>
                        <Box sx={{ width: '90%', m: 'auto', mt: 2, mb: 2 }}>
                            <TagsListEdit
                                tags={bimModel.tags}
                                onTagAdded={onTagAdded}
                                onTagRemoved={onTagRemoved}
                            />
                        </Box>
                    </>
                )}
                <Typography
                    sx={{ m: 1, mb: 2, mt: 2 }}
                    variant="subtitle2"
                    color="text.secondary"
                    component="div"
                >
                    Model Parameters
                </Typography>
                {modelParameters && <BimModelParameters bimModelParameters={modelParameters} />}
                {!hideEmeConfiguration && (
                    <>
                        <Typography
                            sx={{ m: 1, mb: 2, mt: 4 }}
                            variant="subtitle2"
                            color="text.secondary"
                            component="div"
                        >
                            EME Configuration
                        </Typography>
                        {/* <Box sx={{ width: "90%", m: "auto", mt: 1 }}> */}
                        <Box sx={{ width: '95%', m: 'auto' }}>
                            <Stack direction="row">
                                <Box sx={{ width: '70%', m: 'auto' }}>
                                    <FieldsGrid
                                        fields={['nadManufacturer', 'nadModel']}
                                        customAccessors={fieldPropsAccessors}
                                    />
                                </Box>
                                <Button
                                    component="label"
                                    variant="outlined"
                                    onClick={getPortsConfig}
                                    sx={{ marginLeft: 'auto' }}
                                    disabled={false}
                                >
                                    Get config
                                </Button>
                            </Stack>
                            <Typography sx={{ mt: 4 }} component="div" />
                            {portsConfiguration ? (
                                <PortsPowerTable
                                    portsConfiguration={portsConfiguration}
                                    hideExtraColumns={true}
                                    editMode={false}
                                    onChange={undefined}
                                />
                            ) : (
                                ''
                            )}
                        </Box>
                    </>
                )}
            </div>
            <Box sx={{ m: 1, textAlign: 'right' }}>
                <Button
                    component="label"
                    variant="outlined"
                    onClick={onSaveFields}
                    sx={{ marginLeft: 'auto' }}
                    disabled={saveDisabled}
                >
                    Save
                </Button>
                {updateInProgress && (
                    <CircularProgress
                        size={30}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-15px',
                            marginLeft: '-15px',
                        }}
                    />
                )}
            </Box>
        </>
    );
};
