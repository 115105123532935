import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import { Box, Button, CircularProgress, IconButton, Typography } from '@mui/material';
import { List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Avatar } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DownloadIcon from '@mui/icons-material/Download';

import { uploadFile } from '../common/utils';
import { NotificationService } from '../common/NotificationService';
import { BimAttachment, BimFactory, BimModel } from '../factory/bim';
import { BimDialogService } from '../common/BimDialogService';
import { BimApi } from '../api/bim';

// eslint-disable-next-line react/prop-types
export const BimAttachments = ({ bimId }) => {
    const [, setRefresh] = React.useState(false);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const loadInProgressRef = useRef(false);

    const bimModel: BimModel = BimFactory.instances[bimId];
    const { attachments } = bimModel;

    const refreshAll = async () => {
        loadInProgressRef.current = true;
        setRefresh((current) => !current);
        await bimModel.preloadAttachments();
        const needsLoading = bimModel.attachments.filter((attachment) => !attachment.isLoaded);
        console.log(needsLoading);
        await Promise.all(needsLoading.map((attachment: BimAttachment) => attachment.load()));
        loadInProgressRef.current = false;
        setRefresh((current) => !current);
    };

    const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        if (file) {
            setUploadInProgress(true);
            await uploadFile(file, bimId);
            setUploadInProgress(false);
            refreshAll();
        }
    };

    const onDelete = async (attachId) => {
        console.log(`[BimAttachments] deleting attachment ${attachId}`);
        const resp = await BimApi.used.deleteAttachment(bimId, attachId);
        if (resp.status === 204) {
            console.log(`[BimAttachments] SUCCESS deleting attachment ${attachId}`);
            NotificationService.success('Success: deleting attachment');
        } else {
            NotificationService.error('Error: could not delete attachment');
            const errorData = await resp.json();
            console.warn(errorData.message);
        }
        refreshAll();
    };

    useEffect(() => {
        refreshAll();
    }, [bimId]);

    console.log(attachments);
    const loadInProgress = loadInProgressRef.current;

    return (
        <>
            <div
                id="bimAttachments"
                style={{ height: '90%', overflowY: 'auto', position: 'relative' }}
            >
                {loadInProgress ? (
                    <CircularProgress
                        size={30}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-15px',
                            marginLeft: '-15px',
                        }}
                    />
                ) : (
                    <List
                        dense
                        subheader={
                            <ListSubheader sx={{ display: 'flex', p: 0 }}>
                                Attachments
                                <Typography sx={{ flexGrow: 1 }} />
                                {/* <IconButton color="primary">
                            <UploadFileIcon />
                        </IconButton> */}
                            </ListSubheader>
                        }
                    >
                        {attachments.map((attach) => (
                            <ListItem
                                key={attach.id}
                                secondaryAction={
                                    <>
                                        <IconButton
                                            size="small"
                                            edge="end"
                                            sx={{
                                                m: 0.5,
                                                '&:hover': {
                                                    color: 'red',
                                                },
                                            }}
                                            onClick={() =>
                                                BimDialogService.deleteAttachmentDialog(() =>
                                                    onDelete(attach.id),
                                                )
                                            }
                                        >
                                            <DeleteIcon fontSize="small" />
                                        </IconButton>
                                        <IconButton
                                            edge="end"
                                            color="primary"
                                            sx={{ m: 0.5 }}
                                            onClick={() => window.open(attach.url, '_blank')}
                                        >
                                            <DownloadIcon />
                                        </IconButton>
                                    </>
                                }
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <AttachFileIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={attach.title} secondary={attach.type} />
                            </ListItem>
                        ))}
                    </List>
                )}
            </div>
            <Box sx={{ m: 1, position: 'relative', textAlign: 'right' }}>
                <Button
                    component="label"
                    variant="outlined"
                    startIcon={<UploadFileIcon />}
                    sx={{ margin: 'auto', marginRight: 1.5 }}
                >
                    upload
                    <input type="file" hidden onChange={onUpload} />
                </Button>
                {uploadInProgress && (
                    <CircularProgress
                        size={30}
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-15px',
                            marginLeft: '-15px',
                        }}
                    />
                )}
            </Box>
        </>
    );
};
