import { useMsal } from '@azure/msal-react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import type * as React from 'react';

import { MSAL_LOGIN_TYPE, MsalLoginType, loginRequest } from '../../common/Auth';
import MicrosoftEntraIdIcon from '../icons/MicrosoftEntraId';

type Props = {
    error?: string | React.ReactNode;
};

const LoginPage: React.FC<Props> = ({ error }) => {
    const { instance } = useMsal();

    const handleLogin = async (loginType: MsalLoginType) => {
        if (loginType === MsalLoginType.POPUP) {
            return instance.loginPopup(loginRequest);
        }

        if (loginType === MsalLoginType.REDIRECT) {
            return instance.loginRedirect(loginRequest);
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ my: 1, mb: 2, bgcolor: 'primary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>

                <Typography variant="h5" color="textPrimary" gutterBottom textAlign="center">
                    Sign in
                </Typography>

                {error ? (
                    <Alert sx={{ my: 2 }} severity="error">
                        {error}
                    </Alert>
                ) : null}

                <Box sx={{ mt: 2 }}>
                    <Stack spacing={1}>
                        <Button
                            key={'msal.login'}
                            variant="contained"
                            fullWidth
                            size="large"
                            disableElevation
                            name={'provider'}
                            color={'primary'}
                            value={'msal'}
                            startIcon={<MicrosoftEntraIdIcon />}
                            onClick={() => handleLogin(MSAL_LOGIN_TYPE)}
                            sx={{
                                textTransform: 'capitalize',
                                filter: 'opacity(0.9)',
                                transition: 'filter 0.2s ease-in',
                                '&:hover': {
                                    filter: 'opacity(1)',
                                },
                            }}
                        >
                            <span>Sign in</span>
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginPage;
