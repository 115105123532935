import { useAccount, useMsal } from '@azure/msal-react';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

const ProfileName = () => {
    const { instance } = useMsal();
    const account = useAccount(instance.getActiveAccount() || {});
    const [name, setName] = useState('');

    useEffect(() => {
        if (account?.name) {
            setName(account.name);
        } else {
            setName('');
        }
    }, [account]);

    if (name) {
        return <Typography variant="h6">{name}</Typography>;
    }

    return null;
};

export default ProfileName;
