// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useMemo, useState, useEffect } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    TextField,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import { PortConfigurationFields, PortsConfiguration } from '../models/BimFields';
import { PortConfigurationStub } from '../models/BimDataTypes';
import { GenericField, NumInputField } from '../common/GenericFields';
import { NumInputRange } from '../models/DataTypes';
import { RangeInputFields } from '../common/MiscFields';

const TableEditableCell = ({
    getInputValue,
    setInputValue,
    setFinalValue,
    readOnly,
}: {
    getInputValue;
    setInputValue?;
    setFinalValue?;
    readOnly?;
}) => {
    const [, onRefresh] = useState(false);
    const value = getInputValue();
    const editMode = !!setFinalValue;

    return (
        <TableCell align="right">
            {readOnly ? (
                value
            ) : (
                <TextField
                    id="port"
                    size="small"
                    value={value}
                    onBlur={(e) => setFinalValue(e.target.value)}
                    onChange={(e) => {
                        setInputValue(e.target.value);
                        onRefresh((current) => !current);
                    }}
                    disabled={!editMode}
                />
            )}
        </TableCell>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const RangeInputCell = ({ rangeInput }: { rangeInput: NumInputRange }) => {
    const [, onRefresh] = useState(false);
    const rangeInputField = useMemo(() => new RangeInputFields(rangeInput), [rangeInput]);
    // update original object
    rangeInput.min = rangeInputField.min;
    rangeInput.max = rangeInputField.max;

    return (
        <TableCell align="right">
            <Stack direction={'row'}>
                <TextField
                    id="port"
                    size="small"
                    value={rangeInputField.min}
                    onBlur={() => null}
                    onChange={(e) => {
                        rangeInputField.min = e.target.value;
                        onRefresh((current) => !current);
                    }}
                />
                <Typography sx={{ pt: 1 }}>&nbsp;-&nbsp;</Typography>
                <TextField
                    id="port"
                    size="small"
                    value={rangeInputField.max}
                    onBlur={() => null}
                    onChange={(e) => {
                        rangeInputField.max = e.target.value;
                        onRefresh((current) => !current);
                    }}
                />
            </Stack>
        </TableCell>
    );
};

/* eslint-disable react/prop-types */
const PortsPowerTableRow = ({
    rowFields,
    onChange,
    children,
    hideExtraColumns = false,
    editable = false,
    rowNb = null,
}) => {
    const {
        port,
        frequency,
        power,
        eTilt,
        eTiltRange,
    }: {
        frequency: NumInputField;
        port: NumInputField;
        power: NumInputField;
        eTilt: NumInputField;
        eTiltRange: GenericField;
    } = rowFields;
    const frequencyRange = frequency.range?.min + '-' + frequency.range?.max;
    const fieldValueSetter = (field: NumInputField) => (val) => {
        field.value = val;
        onChange();
    };
    const fieldInputValueSetter = (field: NumInputField) => (val) => {
        field.inputValue = val;
        onChange();
    };

    return (
        <TableRow
            key={rowNb !== null ? 'portRow_' + rowNb : 'portRow_new'}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
        >
            <TableEditableCell
                readOnly
                getInputValue={() => port.inputValue}
                setInputValue={rowNb === null && fieldInputValueSetter(port)}
                setFinalValue={rowNb === null && fieldValueSetter(port)}
            />
            <TableEditableCell readOnly getInputValue={() => frequencyRange} />
            <TableEditableCell
                getInputValue={() => frequency.inputValue}
                setInputValue={editable && fieldInputValueSetter(frequency)}
                setFinalValue={editable && fieldValueSetter(frequency)}
            />
            <TableEditableCell readOnly getInputValue={() => eTiltRange.value} />
            {!hideExtraColumns && (
                <TableEditableCell
                    getInputValue={() => eTilt.value}
                    setInputValue={editable && fieldValueSetter(eTilt)}
                    setFinalValue={editable && fieldValueSetter(eTilt)}
                />
            )}
            {!hideExtraColumns && (
                <TableEditableCell
                    getInputValue={() => power.inputValue}
                    setInputValue={editable && fieldInputValueSetter(power)}
                    setFinalValue={editable && fieldValueSetter(power)}
                />
            )}
            <TableCell>{children}</TableCell>
        </TableRow>
    );
};
/* eslint-enable react/prop-types */

export const DEFAULT_PORT_CONFIG = (port: number, freq: number): PortConfigurationStub => {
    return {
        port: port,
        frequency: freq,
        power: 0,
        eTilt: '',
    };
};

const DEFAULT_FREQ_RANGE: NumInputRange = {
    min: 0,
    max: 1000,
};

export const PortsPowerTable = ({
    portsConfiguration,
    editMode,
    hideExtraColumns,
    allowAddDel,
    onChange,
}: {
    portsConfiguration: PortsConfiguration;
    editMode;
    hideExtraColumns?;
    allowAddDel?;
    onChange;
}) => {
    const [updatedRowState, onUpdatedRow] = useState(false);
    const [addedRowState, onAddedRow] = useState(false);

    // renewed each time new row is added
    const newPort = useMemo(() => {
        console.log('[PortsPowerTable] create empty port');
        return new PortConfigurationFields(
            '',
            {
                min: DEFAULT_FREQ_RANGE.min,
                max: DEFAULT_FREQ_RANGE.max,
            },
            '',
        );
    }, [addedRowState]);

    // triggered each time row is added or removed/updated
    useEffect(() => {
        onChange?.();
    }, [addedRowState, updatedRowState]);

    const addRow = () => {
        portsConfiguration.addPortConfiguration(newPort);
        onAddedRow((current) => !current);
    };

    const removeRow = (portNb) => {
        portsConfiguration.removePortConfiguration(portNb);
        onUpdatedRow((current) => !current);
    };

    const rows: PortConfigurationFields[] = Object.values(portsConfiguration.fields);

    return (
        <>
            <TableContainer component={Paper}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Port</TableCell>
                            <TableCell align="right">Freq&nbsp;(MHz) (min&#8209;max)</TableCell>
                            <TableCell align="right">Freq&nbsp;(MHz)</TableCell>
                            <TableCell align="right">E&#8209;tilt range</TableCell>
                            {!hideExtraColumns && <TableCell align="right">E&#8209;tilt</TableCell>}
                            {!hideExtraColumns && (
                                <TableCell align="right">Power&nbsp;(W)</TableCell>
                            )}
                            {editMode && allowAddDel && <TableCell align="right"></TableCell>}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows?.map((r) => (
                            // eslint-disable-next-line react/jsx-key
                            <PortsPowerTableRow
                                hideExtraColumns={hideExtraColumns}
                                rowNb={r.primaryKey}
                                rowFields={r.fields}
                                editable={editMode}
                                onChange={() => onUpdatedRow((current) => !current)}
                            >
                                {allowAddDel && editMode && (
                                    <IconButton onClick={() => removeRow(r.primaryKey)}>
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                )}
                            </PortsPowerTableRow>
                        ))}
                        {allowAddDel && editMode && (
                            <PortsPowerTableRow
                                hideExtraColumns={hideExtraColumns}
                                rowFields={newPort.fields}
                                editable={true}
                                onChange={onChange}
                            >
                                <IconButton
                                    aria-label="cancel"
                                    onClick={addRow}
                                    disabled={!newPort.isValid()}
                                >
                                    <AddIcon />
                                </IconButton>
                            </PortsPowerTableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};
