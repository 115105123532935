import { red } from '@mui/material/colors';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';

// Create a theme instance.
export const theme = createMuiTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
        background: {
            default: '#fff',
        },
    },
});
