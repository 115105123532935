// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useRef } from 'react';
import {
    Grid,
    TextField,
    IconButton,
    Stack,
    CircularProgress,
    OutlinedInput,
    InputAdornment,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Autocomplete,
    Tooltip,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { DEFAULT_MUI_SIZE } from '../BimAdmin';
import { adjustInputSize, DataFieldsAccessors } from '../../common/GenericFields';

const WIDE_FIELD_TRESHOLD = 30;

/**
 * Multi fields grid display with custom properties support
 * @param fields iterable array of items
 * @param customAccessors accessors to field properties:
 * - mandatory: getFieldName, getFieldValue, setFieldValue
 * - optional: getFieldId, getFieldValidity, getFieldWidth, isEditable, isNumeric
 */
export const FieldsGrid = ({
    fields,
    customAccessors,
    ...otherProps
}: {
    fields;
    customAccessors: DataFieldsAccessors;
    otherProps?;
}) => {
    const [, setRefresh] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const isWideField = (fieldValue) => fieldValue?.length > WIDE_FIELD_TRESHOLD;

    const {
        getFieldName,
        getFieldValue,
        getInputType = () => '',
        getFieldId = getFieldName,
        getCustomWidth = (item) =>
            adjustInputSize((getFieldValue(item) as { length?: number })?.length),
    } = customAccessors;

    // common
    const {
        setFieldValue,
        getFieldValidity = () => true,
        getCustomMsg = () => '',
        isEditable = () => true,
    } = customAccessors;

    // text only
    const { setFinalValue = () => '', getUnits = () => '' } = customAccessors;
    // select only
    const { getEnumValues, getOptionLabel } = customAccessors;

    const updateField = (field, inputValue) => {
        if (setFieldValue) {
            setFieldValue(field, inputValue);
            setRefresh((current) => !current);
        }
    };

    return (
        <Grid container spacing={fields.length > 1 ? 2 : 0}>
            {fields?.map((item) => {
                const type = getInputType(item);
                return (
                    <Grid item key={'field_' + getFieldId(item)} xs={getCustomWidth?.(item)}>
                        {type === 'select' ? (
                            <FieldSelect
                                name={getFieldName(item)}
                                options={(getEnumValues as (key: string) => string[])(item)}
                                selected={getFieldValue(item)}
                                setSelected={
                                    isEditable(item) ? (val) => setFieldValue(item, val) : null
                                }
                                getOptionLabel={getOptionLabel}
                                validity={getFieldValidity(item)}
                            />
                        ) : (
                            <TextField
                                // key={'field_' + getFieldId(item)}
                                label={
                                    <Tooltip title={getFieldName(item)} placement="top">
                                        <span>{getFieldName(item)}</span>
                                    </Tooltip>
                                }
                                variant="outlined"
                                value={getFieldValue(item) || ''}
                                onChange={(e) => updateField(item, e.target.value)}
                                onBlur={(e) => setFinalValue(item, e.target.value)}
                                size={DEFAULT_MUI_SIZE}
                                error={!getFieldValidity(item)}
                                disabled={!isEditable(item)}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                helperText={!getFieldValidity(item) ? getCustomMsg(item) : ''}
                                fullWidth
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {getUnits(item)}
                                        </InputAdornment>
                                    ),
                                    // This start adornment is only here to prevent the label from moving inside the text box
                                    startAdornment: (
                                        <InputAdornment position="start"></InputAdornment>
                                    ),
                                }}
                                {...otherProps}
                            />
                        )}
                    </Grid>
                );
            })}
        </Grid>
    );
};

/* eslint-disable react/prop-types */
export const FieldSelect = ({
    name, // item.name
    options, // itema.options
    getOptionValue = (item) => item,
    getOptionLabel = (item) => item,
    selected, // selected option
    setSelected, // callback for setting selected value, if not provided field is automatically disabled
    validity,
    hidden = false,
}) => {
    return (
        <FormControl fullWidth size="small">
            <InputLabel id="demo-select-small">{name}</InputLabel>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={selected || ''}
                label={name}
                onChange={(e) => setSelected(e.target.value)}
                error={!validity()}
                disabled={!setSelected}
                hidden={hidden}
            >
                {options.map((opt) => (
                    <MenuItem key={getOptionValue(opt)} value={getOptionValue(opt)}>
                        {getOptionLabel(opt)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};
/* eslint-enable react/prop-types */

// multiline threshold: max chars count per line before switching to multiline
const MAX_SINGLE_LINE_LENGTH = 20;

// eslint-disable-next-line react/prop-types
export const FieldsStack = ({ items, setFieldData, lineSplitLenght = MAX_SINGLE_LINE_LENGTH }) => {
    console.log(items);
    /* eslint-disable react/prop-types */
    return (
        <Stack spacing={2} justifyContent="center" alignItems="center">
            {items.map(([key, val]) => {
                const value = val?.value !== undefined ? val.value : val || '';
                const inputProps: Record<string, string> | null = !isNaN(value)
                    ? { inputMode: 'numeric', pattern: '[0-9]*' }
                    : null;
                // const isFullWidth = typeof value === "string"
                const isMultiline = isNaN(value) && value.length > lineSplitLenght;
                return (
                    <TextField
                        key={key}
                        label={val?.label || key}
                        variant="outlined"
                        value={value}
                        onChange={(e) => setFieldData?.(key, e.target.value)}
                        style={{ marginBottom: '5px' }}
                        size={DEFAULT_MUI_SIZE}
                        disabled={!setFieldData}
                        inputProps={inputProps || {}}
                        multiline={isMultiline}
                        fullWidth={!inputProps}
                    />
                );
            })}
        </Stack>
    );
    /* eslint-enable react/prop-types */
};

export const EditSaveField = ({
    initialValue,
    onSave,
    defaultEdit,
}: {
    initialValue;
    onSave?;
    defaultEdit?;
}) => {
    const [editMode, setEditMode] = useState(!!defaultEdit);
    const [saveInProgress, setSaveInProgress] = useState(false);
    const ref = useRef<{ children: { value: unknown }[] }>();

    const toggleEditSave = () => {
        if (editMode) {
            setSaveInProgress(true);
            const value = ref.current?.children?.[0]?.value;
            onSave(value).then(() => {
                console.log('[EditSaveField] done saving edit');
                setSaveInProgress(false);
                setEditMode(!editMode);
            });
        } else {
            setEditMode(!editMode);
        }
    };

    return (
        <OutlinedInput
            ref={ref}
            defaultValue={initialValue}
            size="small"
            label="Name"
            endAdornment={
                onSave ? (
                    <InputAdornment position="end">
                        <IconButton
                            onClick={toggleEditSave}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {editMode ? <SaveIcon /> : <EditIcon />}
                            {saveInProgress && (
                                <CircularProgress
                                    size={30}
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-15px',
                                        marginLeft: '-15px',
                                    }}
                                />
                            )}
                        </IconButton>
                    </InputAdornment>
                ) : (
                    <></>
                )
            }
            disabled={!editMode}
            // label="Password"
        />
    );
};

/**
 * Update tags at each edit
 */
// rename to InstantTagEdit or SingleTagEdit
export const TagsListEdit = ({
    name = 'Tags',
    tags,
    onTagAdded,
    onTagRemoved,
}: {
    name?;
    tags;
    onTagAdded?;
    onTagRemoved?;
}) => {
    // add/remove tag in server
    const onTagsChange = async (_event: unknown, newTags: string[]) => {
        // const tags = Object.keys(bimCopy.tags)
        // compute diff to find added or removed tag
        const diffCount = newTags.length - tags.length;
        const isAdded = diffCount > 0;
        const tagsCount = {};
        [...tags, ...newTags].forEach(
            (tagName) => (tagsCount[tagName] = tagsCount[tagName] ? tagsCount[tagName] + 1 : 1),
        );
        const tag = Object.entries(tagsCount).find(([, count]) => count === 1)?.[0];
        isAdded ? onTagAdded(tag) : onTagRemoved(tag);
    };

    return (
        <Autocomplete
            multiple
            id="tagsEditableList"
            options={[]}
            value={tags}
            freeSolo
            size={DEFAULT_MUI_SIZE}
            // sx={{ m: 2 }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={name}
                    placeholder="Type and press enter"
                />
            )}
            onChange={onTagsChange}
            disabled={!onTagAdded && !onTagRemoved}
        />
    );
};
