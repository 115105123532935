import { BimFactory } from '../factory/bim';
import {
    CylinderShapeFields,
    CylinderShapeStub,
    DEFAULT_SHAPE_TYPE,
    RectangleShapeFields,
    RectangleShapeStub,
    ShapeType,
} from '../common/ShapeInputFields';
import {
    BimApiStub,
    BimParameterStub,
    BimType,
    BimSubtype,
    generateBimRequiredFieldsDefaultStub,
    BimRequiredFieldsStub,
    AntennaPatternsStub,
    PortConfigurationStub,
    MeshItemReference,
    MeshReferenceType,
} from './BimDataTypes';
import { GenericField, FieldsCollection, NumInputField } from '../common/GenericFields';
import { defaultIfUndef, combinePorts } from '../common/utils';
import { NumInputRange } from './DataTypes';
import { DEFAULT_PORT_CONFIG } from '../components/PortsPowerTable';

export class BimInfoFields extends FieldsCollection {
    constructor(bimStub?: BimApiStub) {
        super();
        this.initFromStub(bimStub);
    }

    get name() {
        return this.getFieldValue('name');
    }

    set name(val) {
        this.setFieldValue('name', val);
    }

    get bimId() {
        return this.getFieldValue('bimId');
    }

    set bimId(val) {
        this.setFieldValue('bimId', val);
    }

    isNameUnique() {
        const match = Object.values(BimFactory.instances).find(
            (item) => item.id !== this.id && item.name === this.name,
        );
        return !match;
    }

    isValidField(field) {
        return field === 'name'
            ? super.isValidField(field) && this.isNameUnique()
            : super.isValidField(field);
    }

    isValid() {
        return this.isValidField('name');
    }

    initFromStub(bimStub?: BimApiStub) {
        this.initField('name', new GenericField(defaultIfUndef(bimStub?.displayName), true));
        this.initField('bimId', new GenericField(defaultIfUndef(bimStub?.id), true));
    }

    toStub() {
        return Object.values(this.fields).map((field) => ({
            ...field.originalStub,
            value: field.value,
        }));
    }
}

export class BimMeshReferenceFields extends FieldsCollection {
    constructor(meshReference?: MeshItemReference) {
        super();
        this.initFromStub(meshReference);
    }

    get id(): unknown {
        return this.getFieldValue('id');
    }

    set id(val: unknown) {
        this.setFieldValue('id', val);
    }

    get type(): MeshReferenceType {
        return this.getFieldValue('type');
    }

    set type(val: MeshReferenceType) {
        this.setFieldValue('type', val);
    }

    initFromStub(meshReference?: MeshItemReference) {
        this.initField('id', new GenericField(defaultIfUndef(meshReference?.id), true));
        this.initField('type', new GenericField(defaultIfUndef(meshReference?.type), true));
    }

    toStub(): MeshItemReference {
        return {
            id: this.id as string | number,
            type: this.type,
        } as MeshItemReference;
    }
}

/**
 * Clean any deprecated keys
 * @param collection
 * @returns
 */
function cleanBimObjectKeys(collection) {
    // legacy keys type/subtype support
    if (collection.subType) {
        console.warn(
            `deprecated 'subType' key found => substitute 'type' , 'typeClass' with 'type' `,
        );
        collection.typeClass = collection.type;
        collection.type = collection.subType;
        delete collection.subType;
    }

    const defaultStub = generateBimRequiredFieldsDefaultStub();

    for (const key of Object.keys(collection)) {
        if (defaultStub[key] === undefined) {
            console.debug(`unexpected key found: ${key}`);
        }
    }

    return collection;
}

export class BimRequiredFields extends FieldsCollection {
    originalStub;

    constructor(bimStub?: BimApiStub) {
        super();
        this.stub = bimStub; // does not assign directly to property but call stub setter instead
    }

    get stub() {
        return this.originalStub;
    }

    set stub(stub) {
        this.originalStub = stub;
        this.initFromStub();
    }

    initFromStub(stub: BimRequiredFieldsStub = this.originalStub) {
        const defaultStub = generateBimRequiredFieldsDefaultStub();
        const cleanedStub = cleanBimObjectKeys({ ...defaultStub, ...stub });
        const mergedStub = { ...defaultStub, ...cleanedStub };
        const mandatoryFields = {
            typeClass: true,
            type: true,
            shape: true,
        };

        const fields = Object.fromEntries(
            Object.entries(mergedStub)
                .filter(([k]) => k !== 'shapeDimensions') // shape dims will be initialized separately
                .map(([k, v]) => [k, new GenericField(v, mandatoryFields[k])]),
        );
        Object.assign(this.fields, fields);

        // handle other fields
        const shapeTypeField: GenericField = this.fields['shape'];
        const shapeType = shapeTypeField?.value || DEFAULT_SHAPE_TYPE;
        const shapeDimensionsStub = stub?.['shapeDimensions'];

        // init shape dimensions according to shape type
        this.fields['shapeDimensions'] =
            shapeType === ShapeType.CYLINDER
                ? new CylinderShapeFields(shapeDimensionsStub as CylinderShapeStub)
                : new RectangleShapeFields(shapeDimensionsStub as RectangleShapeStub);
    }

    get uid() {
        return this.getFieldValue('uid');
    }

    get makeModel() {
        return this.getFieldValue('makeModel');
    }

    get manufacturer() {
        return this.getFieldValue('manufacturer');
    }

    get esa() {
        return this.getFieldValue('esa');
    }

    get nadModel() {
        return this.getFieldValue('nadModel');
    }

    get nadManufacturer() {
        return this.getFieldValue('nadManufacturer');
    }

    get typeClass(): BimType {
        return this.getFieldValue('typeClass');
    }

    get type(): BimSubtype {
        return this.getFieldValue('type');
    }

    get shape(): ShapeType {
        return this.getFieldValue('shape');
    }

    /**
     * non generic typed field, getter only
     */
    get shapeDimensions(): FieldsCollection {
        return this.getField('shapeDimensions');
    }

    set makeModel(val) {
        this.setFieldValue('makeModel', val);
    }

    set manufacturer(val) {
        this.setFieldValue('manufacturer', val);
    }

    set esa(val) {
        this.setFieldValue('esa', val);
    }

    set nadModel(val) {
        this.setFieldValue('nadModel', val);
    }

    set nadManufacturer(val) {
        this.setFieldValue('nadManufacturer', val);
    }

    set typeClass(typeClass: BimType) {
        // on typeClass change reset type
        if (typeClass !== this.typeClass) {
            this.setFieldValue('type', '');
        }
        this.setFieldValue('typeClass', typeClass);
    }

    set type(val: BimSubtype) {
        this.setFieldValue('type', val);
    }

    set shape(val: ShapeType) {
        // on shape type change, (re)set corresponding shape dims
        if (val !== this.shape) {
            // replace shape dims with fresh object
            this.fields['shapeDimensions'] =
                val === ShapeType.CYLINDER ? new CylinderShapeFields() : new RectangleShapeFields();
        }

        this.setFieldValue('shape', val);
    }

    filterInputFields() {
        return ['makeModel', 'manufacturer', 'esa'].map((name) => ({
            name,
            value: this[name] || '',
        }));
    }

    toStub() {
        return this.getPendingChanges();
    }
}

/**
 * defines BimParameterField type wrapping server stub
 */

export class BimParameterField extends GenericField {
    originalStub: BimParameterStub;

    constructor(stub: BimParameterStub) {
        super(stub.value !== undefined ? stub.value : stub.default);
        this.originalStub = stub;
    }

    get name() {
        return this.originalStub.name;
    }

    get label() {
        return this.originalStub.displayName;
    }
}

export class BimParameters extends FieldsCollection {
    constructor(stubItems?: [BimParameterStub]) {
        super();
        this.fromStub(stubItems);
    }

    // init from stub
    fromStub(stubItems?: [BimParameterStub]) {
        stubItems?.forEach((i) => this.initField(i.name, new BimParameterField(i)));
    }

    toStub() {
        return Object.values(this.fields).map((field) => ({
            ...field.originalStub,
            value: field.value,
        }));
    }

    pendingChanges() {
        const pendingChanges = {};
        Object.values(this.fields)
            .filter((field) => field.pendingVal !== undefined)
            .forEach((field) => (pendingChanges[field.name] = field.value));
        return pendingChanges;
    }
}

export class PortConfigurationFields extends FieldsCollection {
    constructor(
        port,
        freqMinMax: NumInputRange,
        eTiltRange: string,
        measurementFrequency: number = 0,
        customPortsConfig?: PortConfigurationStub,
    ) {
        super();
        console.debug(`[PortPowerFields] constructor`);
        const portsConfig =
            customPortsConfig || DEFAULT_PORT_CONFIG(port, measurementFrequency, eTiltRange);
        this.fromStub(port, freqMinMax, eTiltRange, portsConfig);
    }

    get port() {
        return this.getFieldValue('port');
    }

    set port(val) {
        this.setFieldValue('port', val);
    }

    get frequency() {
        return this.getFieldValue('frequency');
    }

    set frequency(val) {
        this.setFieldValue('frequency', val);
    }

    get power() {
        return this.getFieldValue('power');
    }

    set power(val) {
        this.setFieldValue('power', val);
    }

    get eTilt() {
        return this.getFieldValue('eTilt');
    }

    set eTilt(val) {
        this.setFieldValue('eTilt', val);
    }

    get eTiltRange() {
        return this.getFieldValue('eTiltRange');
    }

    // unique identifier as combination of port, minFraq and maxFreq
    get primaryKey() {
        const frequency = this.getField('frequency') as NumInputField;
        return this.port + '_' + frequency.range?.min + '-' + frequency.range?.max;
    }

    fromStub(
        port,
        freqMinMax: NumInputRange,
        eTiltRange: string,
        customPortsConfig?: PortConfigurationStub,
    ) {
        const powerRange: NumInputRange = { min: 0, max: NaN };
        this.initField('port', new NumInputField(defaultIfUndef(port), 'port'));

        this.initField(
            'frequency',
            new NumInputField(
                defaultIfUndef(customPortsConfig?.frequency),
                'frequency',
                freqMinMax,
            ),
        );

        this.initField(
            'power',
            new NumInputField(defaultIfUndef(customPortsConfig?.power), 'power', powerRange),
        );

        this.initField('eTiltRange', new GenericField(defaultIfUndef(eTiltRange)));

        this.initField('eTilt', new GenericField(defaultIfUndef(customPortsConfig?.eTilt), true));
    }

    toStub() {
        const stubExport: PortConfigurationStub = {};
        const freqField: NumInputField = this.getField('frequency');
        const freqRange: NumInputRange = freqField.range;
        stubExport.freqMin = freqRange.min;
        stubExport.freqMax = freqRange.max;
        const fields = Object.fromEntries(
            Object.entries(this.fields)
                .filter(([k]) => k !== 'eTiltRange' && k !== 'primaryKey')
                .map(([k, f]) => [k, f.value]),
        );
        Object.assign(stubExport, fields);
        return stubExport;
    }
}

export class PortsConfiguration extends FieldsCollection {
    initialStub; // copy of antenna patterns stub for saving in annotation
    hasChanged = false;

    constructor(apdStub?: AntennaPatternsStub[], configStubList?: PortConfigurationStub[]) {
        super();
        this.initialStub = apdStub;
        this.fromStub(apdStub, configStubList);
    }

    addPortConfiguration(portConfig: PortConfigurationFields) {
        const { primaryKey } = portConfig;
        if (!this.fields[primaryKey]) {
            this.initField(primaryKey, portConfig);
            this.hasChanged = true;
        } else {
            console.warn(
                `[PortsConfiguration::addPortConfiguration] key ${primaryKey} already exists, cannot store 2 configuration with same port and frequency range`,
            );
        }
    }

    removePortConfiguration(primaryKey: string) {
        delete this.fields[primaryKey];
        // avoid skipping pending changes when deleting row
        this.hasChanged = true;
    }

    // init from stub
    fromStub(apdStub: AntennaPatternsStub[] = [], configStubList: PortConfigurationStub[] = []) {
        // Sort By Increasing Port value
        apdStub.sort((a, b) => a.port - b.port);

        const consolidated: AntennaPatternsStub[] = [];

        apdStub.forEach((patternStub) => {
            const existingPort = consolidated.find(
                (p) =>
                    p.port === patternStub.port &&
                    p.minimumFrequency === patternStub.minimumFrequency &&
                    p.maximumFrequency === patternStub.maximumFrequency,
            );

            if (existingPort) {
                combinePorts(existingPort, patternStub);
            } else {
                consolidated.push(patternStub);
            }
        });

        const usedConfigData: PortConfigurationStub[] = [];
        consolidated.forEach((element) => {
            const {
                port,
                minimumFrequency,
                maximumFrequency,
                electricalTilt,
                measurementFrequency,
            } = element;

            const matchingConfigStub = configStubList.find(
                (p) =>
                    p.port === port &&
                    p.freqMin === minimumFrequency &&
                    p.freqMax === maximumFrequency,
            );

            if (matchingConfigStub) {
                usedConfigData.push(matchingConfigStub);
            }

            const freqRange: NumInputRange = { min: minimumFrequency, max: maximumFrequency };

            this.addPortConfiguration(
                new PortConfigurationFields(
                    port,
                    freqRange,
                    electricalTilt,
                    measurementFrequency,
                    matchingConfigStub,
                ),
            );
        });

        configStubList
            .filter((e) => !usedConfigData.includes(e))
            ?.forEach((element) => {
                const freqRange: NumInputRange = {
                    min: defaultIfUndef(element?.freqMin, 0),
                    max: defaultIfUndef(element?.freqMax, 0),
                };
                this.addPortConfiguration(
                    new PortConfigurationFields(
                        element.port,
                        freqRange,
                        '', // disabling for now for custom input port configuration
                        element.frequency,
                    ),
                );
            });
    }

    toStub() {
        return Object.values(this.fields).map((f: PortConfigurationFields) => f.toStub());
    }

    hasPendingChanges() {
        return super.hasPendingChanges() || this.hasChanged;
    }

    getPendingChanges(): PortConfigurationFields[] {
        const modifiedPorts = super.getPendingChanges();
        console.log('[PortsConfiguration::getPendingChanges]', modifiedPorts);
        return Object.values(modifiedPorts);
    }
}
