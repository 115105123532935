// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

// Internal
import { BimModel } from '../factory/bim';
import {
    adjustInputSize,
    DataFieldsAccessors,
    GenericField,
    NumInputField,
} from '../common/GenericFields';
import { ShapeTypes } from '../common/ShapeInputFields';
import { BimRequiredFields, BimParameters, BimParameterField } from '../models/BimFields';
import { FieldsGrid, FieldSelect } from './shared/FieldsDisplay';
import {
    BimGroundSubtypes,
    BimTowerSubtypes,
    BimType,
    BimTypes,
    MeshReferenceType,
} from '../models/BimDataTypes';

export const BimInfos = ({
    bimModel,
    validate,
    editable,
    onValidityChange,
    displayIdAndName = true,
}: {
    bimModel: BimModel;
    validate?;
    editable?;
    onValidityChange?;
    displayIdAndName?;
}) => {
    const { infoFields } = bimModel;

    const getCustomWidth = (key) => {
        const fieldVal = bimModel[key];
        const defaultWidth = adjustInputSize(fieldVal?.length);
        return key === 'name' ? Math.max(4, defaultWidth) : defaultWidth;
    };

    const checkValidity = (key) => {
        const fieldValidity = infoFields.isValidField(key);
        onValidityChange?.(fieldValidity);
        return fieldValidity;
    };

    const fieldPropsAccessors: DataFieldsAccessors = {
        getFieldName: (key) => key,
        getFieldValue: (key) => infoFields[key],
        setFieldValue: (key, val) => (infoFields[key] = val),
        getFieldValidity: (key) => (validate ? checkValidity(key) : true),
        getCustomMsg: (key) => (key === 'name' ? 'unique name required' : 'required field'),
        getCustomWidth,
        isEditable: (key) => key !== 'bimId' && editable,
    };

    return (
        <Box sx={{ width: '90%', m: 'auto' }}>
            {displayIdAndName ? (
                <FieldsGrid fields={['bimId', 'name']} customAccessors={fieldPropsAccessors} />
            ) : (
                <FieldsGrid fields={['name']} customAccessors={fieldPropsAccessors} />
            )}
        </Box>
    );
};

export function MeshReference({
    bimModel,
    validate,
    editable,
}: {
    bimModel: BimModel;
    validate?: boolean;
    editable?: boolean;
    onValidityChange?: (fieldValidity: boolean) => void;
}) {
    const { attachments, itemReference } = bimModel;

    const [, setRefresh] = useState<boolean>(false);

    function checkValidity(key: string): boolean {
        return itemReference.isValidField(key);
    }

    const fieldPropsAccessors: DataFieldsAccessors = {
        getFieldName: (key: string) => key,
        getFieldValue: (key: string) => itemReference[key],
        setFieldValue: (key: string, val: unknown) => (itemReference[key] = val),
        getFieldValidity: (key: string) => (validate ? checkValidity(key) : true),
        getCustomWidth: () => 12,
        isEditable: () => editable,
    };

    // TODO: Maybe filter out attachments that can't be used as mesh reference
    const attachmentOptions = attachments.map((a) => ({
        id: a.id,
        type: MeshReferenceType.Attachment,
        title: a.title,
    }));

    // eslint-disable-next-line react/prop-types
    const AttachmentOptions = ({ options }) => (
        <FieldSelect
            name={'Attachments'}
            getOptionLabel={(item) => item.title}
            getOptionValue={(item) => item.id}
            options={options}
            selected={bimModel.itemReference.id}
            validity={() => (validate ? checkValidity('id') : true)}
            setSelected={
                editable
                    ? (val: unknown) => {
                          // update BIM Model Reference
                          bimModel.itemReference.type = MeshReferenceType.Attachment;
                          bimModel.itemReference.id = val;
                          setRefresh((current) => !current);
                      }
                    : null
            }
        />
    );

    return (
        <Stack sx={{ m: 2, mr: 4, ml: 4, width: '80%' }} spacing={2} direction={'row'}>
            <FieldSelect
                name={'Type'}
                options={[MeshReferenceType.Attachment, MeshReferenceType.HyperBim]}
                selected={bimModel.itemReference.type}
                validity={() => (validate ? checkValidity('type') : true)}
                setSelected={
                    editable
                        ? (val: MeshReferenceType) => {
                              console.log('setting mesh reference type');
                              bimModel.itemReference.type = val;
                              setRefresh((current) => !current);
                          }
                        : null
                }
            />
            {bimModel.itemReference.type === MeshReferenceType.HyperBim ? (
                <FieldsGrid fields={['id']} customAccessors={fieldPropsAccessors} />
            ) : (
                <AttachmentOptions options={attachmentOptions} />
            )}
        </Stack>
    );
}

const BIM_TYPE_LABELS = {};
BIM_TYPE_LABELS[BimType.GROUND] = 'Ground';
BIM_TYPE_LABELS[BimType.TOWER] = 'Tower';

/**
 * BIM text input required fields
 * @returns
 */
export function BimCharacteristics({
    bimRequiredFields,
    validate,
    editable,
    onChange,
}: {
    bimRequiredFields: BimRequiredFields;
    validate?;
    editable?;
    onChange?;
}) {
    const [, setRefresh] = useState(false);
    const checkValidity = (item) => {
        const fieldValidity = bimRequiredFields.isValidField(item.name);
        onChange?.();
        return fieldValidity;
    };
    const fieldPropsAccessors: DataFieldsAccessors = {
        getFieldName: (item) => item.name,
        getFieldValue: (item) => item.value,
        setFieldValue: (field, val) => {
            bimRequiredFields[field.name] = val;
            setRefresh((current) => !current);
        },
        getFieldValidity: (item) => (validate ? checkValidity(item) : true),
        getCustomMsg: () => 'required field',
        isEditable: () => editable,
    };
    return (
        <Box sx={{ width: '90%', m: 'auto' }}>
            <FieldsGrid
                fields={bimRequiredFields.filterInputFields()}
                customAccessors={fieldPropsAccessors}
            />
        </Box>
    );
}

/**
 * BIM select type required field
 * @returns
 */
export function BimTypeSelect({
    bimRequiredFields,
    validate,
    editable,
    onChange,
}: {
    bimRequiredFields: BimRequiredFields;
    validate?;
    editable?;
    onChange?;
}) {
    const [, setRefresh] = useState(false);
    // const typeField: GenericField = bimRequiredFields.getField('typeClass');
    const subTypeField: GenericField = bimRequiredFields.getField('type');

    const bimType = bimRequiredFields.typeClass;
    const bimSubtype = bimRequiredFields.type;
    console.log(`typeClass: ${bimType} type:${bimSubtype}`);

    const checkValidity = (field) => {
        onChange?.();
        return bimRequiredFields.isValidField(field);
    };

    return (
        <>
            <Stack sx={{ m: 2, mr: 4, ml: 4, width: '80%' }} spacing={4} direction={'row'}>
                <FieldSelect
                    name={'Type'}
                    options={BimTypes}
                    selected={bimType}
                    getOptionLabel={(optVal) => BIM_TYPE_LABELS[optVal]}
                    getOptionValue={(optVal) => optVal}
                    validity={() => (validate ? checkValidity('typeClass') : true)}
                    setSelected={
                        editable
                            ? (val) => {
                                  bimRequiredFields.typeClass = val;
                                  setRefresh((current) => !current);
                              }
                            : null
                    }
                />
                {subTypeField && (
                    <FieldSelect
                        name={'Subtype'}
                        options={bimType === BimType.TOWER ? BimTowerSubtypes : BimGroundSubtypes}
                        selected={bimSubtype}
                        getOptionLabel={(optVal) => optVal}
                        getOptionValue={(optVal) => optVal}
                        validity={() => (validate ? checkValidity('type') : true)}
                        setSelected={
                            editable
                                ? (val) => {
                                      bimRequiredFields.type = val;
                                      setRefresh((current) => !current);
                                  }
                                : null
                        }
                    />
                )}
            </Stack>
        </>
    );
}

/**
 * BIM shape & dimension required fields
 */
export function BimShapeDim({
    bimRequiredFields,
    validate,
    editable,
    onChange,
}: {
    bimRequiredFields: BimRequiredFields;
    validate?;
    editable?;
    onChange?;
}) {
    const [, setRefresh] = useState(false);

    const fieldPropsAccessors: DataFieldsAccessors = {
        getFieldName: (kv) => kv[0],
        getFieldValue: (kv) => (kv[1] as NumInputField).inputValue,
        setFieldValue: (kv, val) => {
            const field: NumInputField = kv[1];
            field.inputValue = val;
            setRefresh((current) => !current);
        },
        setFinalValue: (kv, finalValue) => {
            const field: NumInputField = kv[1];
            field.value = finalValue;
            onChange?.();
        },
        getFieldValidity: (kv) => (validate ? (kv[1] as NumInputField).isValid() : true),
        getCustomMsg: () => 'required field',
        isEditable: () => editable,
        getUnits: () => 'm',
    };

    return (
        <Stack sx={{ m: 2 }} direction={'row'}>
            <div style={{ width: '30%', marginRight: '20px' }}>
                <FieldSelect
                    name={'Shape'}
                    options={ShapeTypes}
                    selected={bimRequiredFields.shape}
                    getOptionLabel={(elt) => elt}
                    getOptionValue={(elt) => elt}
                    validity={() => (validate ? bimRequiredFields.isValidField('shape') : true)}
                    setSelected={
                        editable
                            ? (val) => {
                                  bimRequiredFields.shape = val;
                                  onChange?.();
                              }
                            : null
                    }
                />
            </div>
            {/* <div style={{ marginTop: '8px' }}> */}
            {bimRequiredFields.isValidField('shape') && (
                <FieldsGrid
                    fields={Object.entries(bimRequiredFields.shapeDimensions.fields)}
                    customAccessors={fieldPropsAccessors}
                />
            )}
            {/* </div> */}
        </Stack>
    );
}

export const BimModelParameters = ({
    bimModelParameters,
    editable,
}: {
    bimModelParameters: BimParameters;
    editable?: boolean;
}) => {
    const fieldPropsAccessors: DataFieldsAccessors = {
        getFieldName: (field) => bimModelParameters.getField(field.name)?.originalStub?.displayName,
        getFieldValue: (field) => bimModelParameters.getFieldValue(field.name),
        getFieldId: (item) => item?.originalStub?.name,
        setFieldValue: (field, val) => bimModelParameters.setFieldValue(field.name, val),
        isEditable: () => editable,
        getUnits: (field) => bimModelParameters.getField(field.name)?.originalStub?.units,
        getCustomWidth: () => 4,
    };
    const { fields } = bimModelParameters;
    const iterableFields: BimParameterField[] = Object.values(fields);

    return (
        <Box sx={{ width: '90%', m: 'auto' }}>
            {iterableFields && (
                <FieldsGrid fields={iterableFields} customAccessors={fieldPropsAccessors} />
            )}
        </Box>
    );
};

export const SectionSeparator = () => (
    <Divider sx={{ width: '50%', m: 'auto', mt: 3, mb: 3 }}></Divider>
);

// eslint-disable-next-line react/prop-types
export const SectionTitle = ({ name }) => (
    <Typography sx={{ m: 1, mb: 2 }} variant="subtitle1" color="text.secondary" component="div">
        {name}
    </Typography>
);

// eslint-disable-next-line react/prop-types
export const Subtitle = ({ name }) => (
    <Typography sx={{ m: 1 }} variant="subtitle2" color="text.secondary" component="div">
        {name}
    </Typography>
);

// const sectionTitleStyle = {};

// eslint-disable-next-line react/prop-types
export const TextInfo = ({ text }) => {
    return (
        <Typography
            sx={{ m: 'auto', width: '10%' }}
            color="text.secondary"
            display="block"
            variant="caption"
        >
            {text}
        </Typography>
    );
};

// eslint-disable-next-line react/prop-types
export const IconTitle = ({ Icon, titleName }) => (
    <Stack direction="row" sx={{ m: 2, marginBottom: 3 }}>
        <Icon fontSize="small" sx={{ marginRight: 1 }} />
        <Typography variant="subtitle1" color="text.secondary" component="div">
            {titleName}
        </Typography>
    </Stack>
);

// Scroll navigation
// eslint-disable-next-line react/prop-types
export const ScrollSections = ({ children }) => {
    /* eslint-disable react/prop-types */
    return (
        <>
            {children.map((child, i) => (
                <>
                    {i && <SectionSeparator />}
                    <section>{child}</section>
                </>
            ))}
        </>
    );
    /* eslint-enable react/prop-types */
};
