import { useMsal } from '@azure/msal-react';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';

import { MSAL_LOGIN_TYPE, MsalLoginType } from '../../common/Auth';

export const SignOutButton = () => {
    const { instance } = useMsal();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleLogout = (logoutType: MsalLoginType) => {
        setAnchorEl(null);

        if (logoutType === MsalLoginType.POPUP) {
            instance.logoutPopup({
                mainWindowRedirectUri: '/',
            });
        } else if (logoutType === MsalLoginType.REDIRECT) {
            instance.logoutRedirect();
        }
    };

    return (
        <div>
            <IconButton onClick={(event) => setAnchorEl(event.currentTarget)} color="inherit">
                <AccountCircle />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => handleLogout(MSAL_LOGIN_TYPE)} key="logout">
                    Logout
                </MenuItem>
            </Menu>
        </div>
    );
};
