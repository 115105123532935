import { NumInputRange } from '../models/DataTypes';
import { defaultIfUndef, isNotEmpty } from './utils';

/**
 * Fields props accessors required by FieldsGrid component
 */
export interface DataFieldsAccessors {
    getFieldName;
    getFieldValue;
    setFieldValue;
    setFinalValue?; // textfield only
    getInputType?; // support for different input type
    getCustomWidth?;
    getFieldId?;
    getFieldValidity?;
    getCustomMsg?;
    isEditable?;
    isNumeric?; // textfield only
    getUnits?; // textfield only
    getEnumValues?; // selectfield only
    getOptionLabel?; // selectfield only
}

export type FieldOrCollection = GenericField | FieldsCollection;

export class GenericField {
    isMandatory = false;
    initialVal;
    pendingVal;

    constructor(initialVal, isMandatory?) {
        this.initialVal = initialVal;
        this.isMandatory = isMandatory;
    }

    get value() {
        return this.hasPendingChanges() ? this.pendingVal : this.initialVal;
    }

    set value(val) {
        this.pendingVal = val !== this.initialVal ? val : undefined;
    }

    isValid(val?): boolean {
        return !this.isMandatory || isNotEmpty(val === undefined ? this.value : val);
    }

    hasPendingChanges() {
        return this.pendingVal !== undefined;
    }
}

export const adjustInputSize = (inputLength) => {
    if (inputLength) {
        if (inputLength >= 30) return 12;
        else if (inputLength >= 12) return 8;
    }
    return 4;
};

/**
 * Numeric Input Field
 */

export class NumInputField extends GenericField {
    label;
    range?: NumInputRange;
    rawInputValue; // value from input field

    constructor(initialVal, inputLabel, inputRange?: NumInputRange, isOptional?) {
        super(initialVal, !isOptional);
        this.rawInputValue = defaultIfUndef(initialVal);
        this.label = inputLabel;
        this.range = inputRange;
    }

    getNumInputValue(inputVal?) {
        inputVal = inputVal !== undefined ? inputVal : super.value;
        return inputVal === '' || inputVal === '-' ? 0 : parseFloat(inputVal);
    }

    checkInputRange(inputVal?) {
        const numVal = this.getNumInputValue(inputVal);
        const lowerRangeMatch = isNaN(this.range?.min) || numVal >= this.range.min;
        const upperRangeMatch = isNaN(this.range?.max) || numVal <= this.range.max;
        const rangeMatch = lowerRangeMatch && upperRangeMatch;

        if (!rangeMatch) {
            console.warn(`${this.label} input: out of range value ${numVal}`);
        }

        return rangeMatch;
    }

    checkInputValidity(inputVal) {
        const isValid = !isNaN(inputVal) || inputVal === '' || inputVal === '-';
        if (!isValid) console.warn(`${this.label} input: invalid value ${inputVal}`);
        return isValid;
    }

    isValid(): boolean {
        return (
            super.isValid(this.inputValue) &&
            this.checkInputValidity(this.inputValue) &&
            this.checkInputRange(this.inputValue)
        );
    }

    get value() {
        return this.getNumInputValue();
    }

    set value(value) {
        const isValidInput = this.checkInputValidity(value) && this.checkInputRange(value);
        super.value = isValidInput ? value : this.value;
        this.rawInputValue = super.value;
    }

    get inputValue() {
        return this.rawInputValue;
    }

    // temporary setter while editing field, must be saved on focus out by calling value setter
    set inputValue(rawInput) {
        const isValidInput = this.checkInputValidity(rawInput);
        this.rawInputValue = isValidInput ? rawInput : this.rawInputValue;
    }
}

/**
 * Multiple fields, supporting different field types
 */
export class FieldsCollection {
    fields: Record<string, GenericField | FieldsCollection> = {};

    initField(fieldId, field) {
        this.fields[fieldId] = field;
    }

    getField(fieldId): GenericField {
        if (!this.fields[fieldId]) {
            console.warn(`[FieldsCollection] undefined field ${fieldId}`);
        }

        return this.fields[fieldId];
    }

    getFieldValue(fieldId) {
        return this.getField(fieldId)?.value;
    }

    setFieldValue(fieldId, fieldVal) {
        return (this.fields[fieldId].value = fieldVal);
    }

    isValidField(fieldId) {
        return this.getField(fieldId)?.isValid();
    }

    isValid() {
        return !Object.entries(this.fields).find(([key, field]) => {
            const isValid = (field as GenericField).isValid();

            if (!isValid) {
                console.warn(`[FieldsCollection] invalid field ${key}`);
            }

            return !isValid;
        });
    }

    hasPendingChanges() {
        return !!Object.values(this.fields).find((f) =>
            (f as FieldOrCollection).hasPendingChanges(),
        );
    }

    // exporting only modified fields
    getPendingChanges() {
        return Object.fromEntries(
            Object.entries(this.fields)
                .filter(([, v]) => (v as FieldOrCollection).hasPendingChanges())
                .map(([k, v]) =>
                    v instanceof FieldsCollection
                        ? [k, v.getPendingChanges()]
                        : [k, (v as GenericField).value],
                ),
        );
    }

    // exporting all fields
    toStub() {
        return Object.fromEntries(
            Object.entries(this.fields).map(([k, v]) => [
                k,
                v instanceof FieldsCollection ? v.toStub() : (v as GenericField).value,
            ]),
        );
    }
}
