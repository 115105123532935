import { EventType, type IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import { useEffect, useState } from 'react';

import { initialiseMsal } from './common/Auth';
import { BimAdmin } from './components/BimAdmin';
import { PageLayout } from './components/layout/PageLayout';
import LoginPage from './components/pages/LoginPage';
import { theme } from './styles/theme';

export function App() {
    const [instance, setInstance] = useState<IPublicClientApplication | undefined>();
    const [error, setError] = useState<string | Error | undefined>(undefined);

    useEffect(() => {
        initialiseMsal()
            .then((instance) => {
                instance.addEventCallback(
                    (event) => {
                        if (event.eventType === EventType.LOGIN_FAILURE && event.error) {
                            const error = event.error;
                            setError(error);
                        }
                    },
                    [EventType.LOGIN_FAILURE],
                );

                setInstance(instance);
            })
            .catch((e) => {
                console.error('Error occured initialising MSAL', e);
                setError(JSON.stringify(e));
            });
    }, []);
    return (
        <>
            <ThemeProvider theme={theme}>
                {instance ? (
                    <MsalProvider instance={instance}>
                        <PageLayout>
                            <AuthenticatedTemplate>
                                <BimAdmin />
                            </AuthenticatedTemplate>
                            <UnauthenticatedTemplate>
                                {/* <Typography variant="h6" align="center">Please sign-in to see your profile information.</Typography> */}
                                <LoginPage error={error?.toString()} />
                            </UnauthenticatedTemplate>
                        </PageLayout>
                    </MsalProvider>
                ) : (
                    <>Initialising...</> // FIXME: Make this pretty
                )}
            </ThemeProvider>
        </>
    );
}
