import AppBar from '@mui/material/AppBar';
import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import ProfileName from '../ui/ProfileName';
import SignInSignOutButton from '../ui/SignInSignOutButton';

const NavBar = () => {
    return (
        <div style={{ flexGrow: 1 }}>
            <AppBar position="static">
                <Toolbar>
                    <Typography style={{ flexGrow: 1 }}>
                        <Link
                            // component={RouterLink}
                            href="/"
                            color="inherit"
                            variant="h6"
                        >
                            SiteSee BIM Admin
                        </Link>
                    </Typography>
                    <ProfileName />
                    <SignInSignOutButton />
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;
