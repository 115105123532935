// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useRef, ChangeEvent } from 'react';
import { CircularProgress, ButtonBase } from '@mui/material';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { styled } from '@mui/material/styles';

const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.down('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 100,
    },
    '&:hover, &.Mui-focusVisible': {
        zIndex: 1,
        '& .MuiImageBackdrop-root': {
            opacity: 0.4,
        },
        '& .uploadBtn': {
            opacity: 0.8,
        },
    },
}));

const ImageSrc = styled('span')({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'contain',
});

const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.1,
    transition: theme.transitions.create('opacity'),
}));

// eslint-disable-next-line react/prop-types
export const ImageUploader = ({ imgUrl, upload }) => {
    const fileInputRef = useRef();
    const [uploadInProgress, setUploadInProgress] = useState(false);

    const onUpload = async (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        console.log(file);
        if (!uploadInProgress && file) {
            setUploadInProgress(true);
            upload(file).then(() => {
                console.log('[ImageUpload] End of thumbnail upload process');
                setUploadInProgress(false);
            });
        }
    };

    return (
        <div style={{ margin: 'auto', marginBottom: '30px' }}>
            <ImageButton
                focusRipple
                style={{
                    width: '100%',
                }}
                onClick={() => fileInputRef.current.click()}
            >
                {imgUrl && <ImageSrc style={{ backgroundImage: `url(${imgUrl})` }} />}
                <ImageBackdrop className="MuiImageBackdrop-root" />
                <Image>
                    <UploadFileIcon className="uploadBtn" fontSize="large" sx={{ opacity: 0.3 }} />
                    {uploadInProgress && (
                        <CircularProgress
                            size={48}
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-24px',
                                marginLeft: '-24px',
                            }}
                        />
                    )}
                </Image>
            </ImageButton>
            <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                disabled={uploadInProgress}
                hidden
                onChange={onUpload}
            />
        </div>
    );
};
