// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { Card, CardContent, CardMedia, List, ListItem } from '@mui/material';

import { BimModel } from '../factory/bim';
import { BimApi } from '../api/bim';

const BimPreviewCard = ({
    item,
    onClick,
    isSelected = false,
}: {
    item: BimModel;
    onClick: (...a: unknown[]) => unknown;
    isSelected: boolean;
}) => {
    const [, setHovered] = useState(false);
    const [editMode, setEditMode] = useState(false);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const updateField = (fieldName, fieldValue) => {
        const changes = {};
        changes[fieldName] = fieldValue;
        return BimApi.used.updateItem(item.id, changes).then(() => setEditMode(false));
    };

    useEffect(() => {
        // if editMode was previously activated, resest it when item is deselected
        if (editMode && !isSelected) {
            console.log('[BimPreviewCard] reset edit mode');
            setEditMode(false);
        }
    }, [isSelected]);

    return (
        <Card
            onClick={onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            sx={{
                width: '100%',
                display: 'flex',
                '&:hover': { border: '1px solid lightgrey', cursor: 'pointer' },
                backgroundColor: isSelected ? '#f0f1f5' : 'white',
                border: isSelected ? '1px solid lightgrey' : '',
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="subtitle1">
                        {item.infoFields.name}
                    </Typography>
                </CardContent>
            </Box>
            <CardMedia
                component="img"
                sx={{ width: 'auto', height: '80px', marginLeft: 'auto' }}
                image={item.thumbnail?.url}
            />
        </Card>
    );
};

// eslint-disable-next-line react/prop-types
export const BimItemsList = ({ items, selectItem, selectedId }) => {
    /* eslint-disable react/prop-types */
    return (
        <List sx={{ width: '100%', maxWidth: 460, bgcolor: 'background.paper' }}>
            {items.map((item) => (
                <ListItem key={item.id} alignItems="flex-start">
                    <BimPreviewCard
                        item={item}
                        onClick={() => selectItem(item.id)}
                        isSelected={item.id === selectedId}
                    />
                </ListItem>
            ))}
        </List>
    );
    /* eslint-enable react/prop-types */
};
