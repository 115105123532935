import type { PublicClientApplication } from '@azure/msal-browser';

import { defaultMsalInstance, getAuthenticationWithScope } from '../common/Auth';
import { TEST_API_URL } from '../common/constants';
import { makeApiRequest } from '../common/utils';
import type { BimApiCreateStub, BimApiUpdateStub } from '../models/BimDataTypes';

const BIM_ENDPOINT = '/api/models';
const APD_ENDPOINT = '/api/apd';

export class BimApi {
    static used: BimApi; // access to used API instance version
    baseUrl: string | undefined;

    // sets used API
    makeDefault() {
        BimApi.used = this;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async addDefaultHeaders(_headers: Headers) {}

    async fetchItems(textFilter?: string, itemsPerPage = 8, pageId = ''): Promise<Response> {
        const { baseUrl } = this;
        const endpoint = `${BIM_ENDPOINT}`;

        const queryParams = {
            includeThumbnailPresignedUrl: 'true',
            ...(textFilter ? { text: textFilter } : {}),
        };
        console.log('[BIM API] query parameters:', queryParams);

        const urlArgs = `?${new URLSearchParams(queryParams)}`;

        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        if (itemsPerPage) {
            headers.append('x-sitesee-page-size', `${itemsPerPage}`);
            pageId.length && headers.append('x-sitesee-page-token', `${pageId}`);
        }

        const req = new Request(baseUrl + endpoint + urlArgs, {
            headers,
        });
        return await fetch(req);
    }

    async fetchDetails(modelId: string, includeParams = true) {
        const endpoint = `${this.baseUrl}${BIM_ENDPOINT}/${modelId}`;
        const params = includeParams ? '?includeParameters=true' : '';
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific header
        headers.append('Cache-Control', 'no-cache');
        const req = new Request(endpoint + params, {
            headers,
        });
        return await fetch(req);
    }

    async buildGlbRequest(
        modelId: string,
        modelParams?: Record<string, number>,
        refresh?: boolean,
    ): Promise<Request> {
        const endpoint = `${this.baseUrl}${BIM_ENDPOINT}/${modelId}`;
        const data = {
            accept: 'model/gltf-binary',
            getLink: true,
            refresh,
            arguments: modelParams ? modelParams : {},
        };
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Cache-Control', 'no-cache');
        headers.append('Content-Type', 'application/json');

        const linkRequest = new Request(`${endpoint}/specialise`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers,
        });

        const { base64, url } = await makeApiRequest<{ base64?: string; url?: string }>(
            linkRequest,
        );

        if (base64) {
            // Was unable to get a link, simply have whole file
            return new Request(`data:model/gltf-binary;base64,${base64}`);
        } else if (url) {
            return new Request(url);
        } else {
            throw new Error('Unexpected response from backend');
        }
    }

    async fetchApd(nadManufacturer: string, nadModel: string, dryRun = false) {
        const searchParams = new URLSearchParams({
            manufacturer: nadManufacturer,
            model: nadModel,
        });
        const endpoint = `${this.baseUrl}${APD_ENDPOINT}/antennaPatterns?${searchParams}`;
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        const req = new Request(endpoint, { method: 'GET', headers });
        return dryRun ? req : await fetch(req);
    }

    async createItem(data: BimApiCreateStub) {
        const endpoint = `${this.baseUrl}${BIM_ENDPOINT}`;
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', '*/*');
        const req = new Request(endpoint, {
            method: 'POST',
            body: JSON.stringify(data),
            headers,
        });
        return await fetch(req);
    }

    async updateItem(modelId: string, data: BimApiUpdateStub) {
        const endpoint = `${this.baseUrl}${BIM_ENDPOINT}/${modelId}/`;
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', '*/*');
        const req = new Request(endpoint, {
            method: 'PATCH',
            body: JSON.stringify(data),
            headers,
        });
        return await fetch(req);
    }

    async deleteItem(modelId: string) {
        const endpoint = `${this.baseUrl}${BIM_ENDPOINT}/${modelId}/`;
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', '*/*');
        const req = new Request(endpoint, {
            method: 'DELETE',
            headers,
        });
        return await fetch(req);
    }

    async pushTag(modelId: string, tagName: string) {
        const endpoint = `${this.baseUrl}${BIM_ENDPOINT}/${modelId}/tags/${tagName}`;
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Content-Type', 'text/plain');
        headers.append('Accept', '*/*');
        const data = ''; // TODO put tag value?
        const req = new Request(endpoint, {
            method: 'PUT',
            body: data,
            headers,
        });
        return await fetch(req);
    }

    async popTag(modelId: string, tagName: string) {
        const endpoint = `${BIM_ENDPOINT}/${modelId}/tags/${tagName}`;
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Accept', '*/*');
        const req = new Request(this.baseUrl + endpoint, {
            method: 'DELETE',
            headers,
        });
        return await fetch(req);
    }

    async listAttachments(modelId: string) {
        const endpoint = `${BIM_ENDPOINT}/${modelId}/attachments`;
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        const req = new Request(this.baseUrl + endpoint, {
            headers,
        });
        return await fetch(req);
    }

    async getAttachment(modelId: string, attachmentId: string) {
        const { baseUrl } = this;
        const endpoint = `${BIM_ENDPOINT}/${modelId}/attachments/${attachmentId}`;
        const urlArgs = '?includePresignedUrl=true';
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        const req = new Request(baseUrl + endpoint + urlArgs, {
            headers,
        });
        return await fetch(req);
    }

    async deleteAttachment(modelId: string, attachmentId: string) {
        const { baseUrl } = this;
        const endpoint = `${BIM_ENDPOINT}/${modelId}/attachments/${attachmentId}`;
        const urlArgs = '';
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', '*/*');
        const req = new Request(baseUrl + endpoint + urlArgs, {
            method: 'DELETE',
            headers,
        });
        return await fetch(req);
    }

    /**
     * AttachmentUpload(STEP #1): get blob store upload url
     */
    async createAttachment(modelId: string, fileType: string, fileName: string) {
        const { baseUrl } = this;
        const endpoint = `${BIM_ENDPOINT}/${modelId}/attachments`;
        const urlArgs = '';
        const data = {
            type: fileType,
            title: fileName,
        };
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', '*/*');
        const req = new Request(baseUrl + endpoint + urlArgs, {
            method: 'POST',
            body: JSON.stringify(data),
            headers,
        });
        return await fetch(req);
    }

    /**
     * AttachmentUpload(STEP #2): send file to blob store using upload infos from createAttachment request
     */
    async uploadAttachment(
        uploadSettings: { headers: Record<string, string>; url: string; method: string },
        fileContent: File,
        dryRun = false,
    ) {
        const headers = new Headers();
        headers.append('content-length', `${fileContent.size}`);
        Object.entries(uploadSettings.headers).forEach(([key, val]) =>
            headers.append(key, val as string),
        );
        const req = new Request(uploadSettings.url, {
            method: uploadSettings.method.toUpperCase(),
            mode: 'cors',
            headers,
            body: fileContent,
        });
        if (!dryRun) {
            try {
                return await fetch(req);
            } catch (error) {
                console.error(error);
            }
        }
        return req;
    }

    /**
     * AttachmentUpload(STEP #3): Finalise uploading after createAttachment and uploadAttachment have been called
     */
    async finaliseAttachment(bimId: string, attachmentId: string, dryRun = false) {
        const { baseUrl } = this;
        const endpoint = `${BIM_ENDPOINT}/${bimId}/attachments/${attachmentId}/finalise`;
        const urlArgs = '';
        const headers = new Headers();
        await this.addDefaultHeaders(headers);
        // add specific headers
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', '*/*');
        const req = new Request(baseUrl + endpoint + urlArgs, {
            method: 'POST',
            headers,
        });
        if (!dryRun) {
            try {
                return await fetch(req);
            } catch (error) {
                console.error(error);
            }
        }
        return req;
    }
}

export class BimLocalApi extends BimApi {
    static singleton: BimLocalApi;

    static getSingleton() {
        return BimLocalApi.singleton || new BimLocalApi();
    }

    constructor() {
        super();
        this.baseUrl = '';
        BimLocalApi.singleton = this;
    }
}

export class BimTestApi extends BimApi {
    static singleton: BimTestApi;

    msalInstance: PublicClientApplication = defaultMsalInstance;

    static getSingleton(): BimTestApi {
        return BimTestApi.singleton || new BimTestApi();
    }

    async addDefaultHeaders(headers: Headers): Promise<void> {
        const { accessToken } = await getAuthenticationWithScope();
        headers.append('Authorization', `Bearer ${accessToken}`);
    }

    constructor() {
        super();
        this.baseUrl = TEST_API_URL;
        BimTestApi.singleton = this;
    }
}
