export enum NOTIF_TYPE {
    SUCCESS,
    ERROR,
}

/**
 * Wrapper around react component to expose notifications
 */
export class NotificationService {
    static setNotification; // react setState callback

    static showNotification(msg, type) {
        NotificationService.setNotification({ msg, type });
    }

    static success(msg) {
        const type = NOTIF_TYPE.SUCCESS;
        NotificationService.setNotification({ msg, type });
    }

    static error(msg) {
        const type = NOTIF_TYPE.ERROR;
        NotificationService.setNotification({ msg, type });
    }

    static clearNotification() {
        NotificationService.setNotification({ msg: '', type: null });
    }
}
